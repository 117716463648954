<template>
  <div
    v-easyadmin="easyadminId"
    :class="contentType"
    class="position-relative bg-cover-center"
    v-if="isContentVisibleToUser()"
    :style="containerStyles"
    grid-with-filters-component
  >
     
    <div class="container" v-if="stripeTextContent.title || stripeTextContent.description" :style="containerStyles">
      <h1>{{stripeTextContent.title}}</h1>
      <div class="row d-none d-md-block mb-4">
        <div v-html="stripeTextContent.description" class="col-12 col-md-8"></div>
      </div>
      <div class="row d-block d-md-none mb-4">
        <div v-html="stripeTextContent.description" class="col-12" :class="{'line-clamp': !toggleViewMore}"></div>
        <a href="" @click.prevent="toggleText" class="d-block mt-2 col-auto link-primary more-text text-uppercase small">{{toggleBtnText}}</a>
      </div>
    </div>

    <grid-fixed
      :content="content"
      :opt-container-content="containerContent"
    >
      <template v-if="content.filters" #grid-header="{resultsCount}">
        <div class="container pb-3">
          <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
              <filters-modal
                :handler="content.name"
                :content="getContentByName('filters')"
                @active-filters="handleActiveFilters"
                :opt-container-content="containerContent"
                :total-results="resultsCount"
                :theme="content.filters_inline ? 'inline' : 'modal'"
                :has-categories="content.filters_category"
                :sorting-order="sortingOrder"
                :order-by="orderBy"
                ref="filtersModal"
              ></filters-modal>
              <template v-if="!content.filters_inline">
                <div class="total-results">
                  <span v-if="resultsCount>=0">{{ resultsCount }} {{ ft('globals.advanced_search.results') }}</span>
                </div>
                <div class="d-flex align-items-stretch">
                  <button class="filter-button btn btn-outline-dark rounded-pill text-uppercase shadow-none d-flex align-items-center" data-toggle="modal" data-target="#categoryFilterModal">
                    <i class="ki ki-filter fa-lg"></i>
                    <span class="d-none d-sm-block pl-3">{{ ft('globals.advanced_search.filter') }}</span><span class="pl-2" v-if="activeFilters>0"> ({{ activeFilters }})</span>
                  </button>
                  <div class="dropdown ml-3 d-flex" v-if="enableOrdering">
                    <a class="filter-button btn btn-outline-dark rounded-pill text-uppercase shadow-none d-flex align-items-center dropdown-toggle" href="#" role="button" id="dropdownOrderingButton" data-display="static" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="ki ki-order fa-lg"></i>
                      <span class="d-none d-sm-block pl-3">{{ ft('globals.advanced_search.order.title') }}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownOrderingButton">
                      <a class="dropdown-item" href="#" @click="order('like')">{{ ft('globals.advanced_search.order.most_liked') }}</a>
                      <a class="dropdown-item" href="#" @click="order('activated_at')">{{ ft('globals.advanced_search.order.less_liked') }}</a>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </grid-fixed>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      activeFilters: 0,
      sortingOrder: 'desc',
      orderBy: 'activated_at',
      results: get(this.content, "children_count", 0),
      toggleViewMore: false
    };
  },
  mounted() {
    Fandom.onEvent('grid-fixed:filters', this.handleUpdateFilters);
  },
  methods: {
    getContentByName(name) {
      const contentName = get(this, ["content", name, "content"], "");
      return Fandom.getContent(contentName);
    },
    handleActiveFilters(count) {
      this.activeFilters = count;
    },
    handleUpdateFilters(count) {
      this.results = count;
    },
    order(order) {
      this.orderBy = order;
      Vue.nextTick(() => {
        Fandom.emit('filter-apply', {
          contentFiltering: this.$refs["filtersModal"].activeFilters,
          handlerContents: this.content.name,
          orderBy: this.orderBy,
          sortingOrder: this.sortingOrder,
          page: 1
        });
      });
    },
    toggleText() {
      this.toggleViewMore=!this.toggleViewMore;
    },
  },
  computed: {
    enableOrdering() {
      return get(this, ["content", "enable_ordering"], false);
    },
    stripeTextContent() {
      return {...this.content, ...this.content.header || {}};
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : "",
        "background-color": this.content.background_color
      };
    },
    toggleBtnText() {
      return this.toggleViewMore ? "ausblenden" : "mehr texte";
    }
  },
};
</script>

<style lang="scss">
.total-results {
  flex: 1;
}

.filter-button {
  @include media-breakpoint-down(md) {
    font-size: .875rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

.line-clamp {
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical; 
  overflow: hidden;

  p {
    margin-bottom: 0;
  }
}

.more-text{
  border: none;
  background: none;
}


</style>
