<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  mounted() {
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
  },
  computed: { },
};

</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" v-if="isContentVisibleToUser()"  article-list>
   <div class="container px-3 px-sm-0">
     <div v-if="content.title" class="row justify-content-center">
        <div class="col-12 col-md-8 py-4">
          <h2>{{content.title}}</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div v-for="(entry, i) in content.list" :key="i" class="col-12 col-md-8 pb-4 pb-sm-4">
          <div class="row align-items-start" :class="{'h-100': !entry.title}">
            <div class="col-12 col-sm-auto pr-lg-1">
              <div class="step-counter h1 m-0 text-primary">{{i+1}}</div>
            </div>
            <div v-if="entry.title" class="col h3 m-0 py-2 step-title">{{entry.title}}</div>
            <div v-else class="d-flex align-items-center col m-0 py-2 step-description h-100" v-html="entry.description"></div>
          </div>
          <div class="row" v-if="entry.title">
            <div class="col-auto d-lg-flex d-none pr-lg-1">
              <div class="step-counter-mock-spacer"></div>
            </div>
            <div class="col d-flex py-2 align-items-center">
              <div class="step-description" v-html="entry.description"></div>
            </div>
          </div>
          <div v-if="entry.image" class="row">
            <div class="col-12 py-3 py-sm-4">
              <img :src="entry.image.url" :alt="entry.image.alt" class= "img-fluid">
            </div>
          </div>
          <div class="row" v-if="entry.footer_title">
            <div class="col-12 col-sm-auto d-lg-flex d-none pr-lg-1">
              <div class="step-counter-mock-spacer"></div>
            </div>
            <div class="col h6 d-flex align-items-center mt-3 m-0 py-2 py-sm-0 step-title">{{entry.footer_title}}</div>
          </div>
          <div class="row" v-if="entry.footer_description">
            <div class="col-auto d-lg-flex d-none pr-lg-1">
              <div class="step-counter-mock-spacer"></div>
            </div>
            <div class="col d-flex py-2 flex-column align-items-center">
              <div v-html="entry.footer_description"></div>
            </div>
          </div>
        </div>
      </div>
   </div>
 </div>
</template>

<style lang="scss">
[article-list] {

.step-counter, .step-counter-mock-spacer {
  font-family: $bressay-font;
  width: 3rem;
}

.step-description {
  p {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(md) {
  .step-counter, .step-counter-mock-spacer {
    width: auto;
  }

}
 

}
</style>

