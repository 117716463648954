<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  methods: {},
  computed: {
    url() {
      return get(this.content, ['url','url'], Fandom.getContentURL(this.content));
    },
    showGradient() {
      return this.content.hide_gradient || false;
    }
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType">
    <a
      class="tile deco-none cursor-pointer"
      :target="getTarget(content.url)"
      :href="url"
      tile-accessory-component
    >
      <div class="embed-responsive embed-responsive-1by1 bg-white">
        <div class="embed-responsive-item bg animate-zoom" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
        <div class="tile__text-section text-center text-uppercase position-absolute w-100 p-3">
          <p v-if="content.title" :style="{'color': content.text_color}">
            {{content.title}}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-accessory-component] {
  background-color: var(--white);
  color: var(--dark);

  &:not([href]):hover {
    color: var(--dark);
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }

  &.tile {
    .embed-responsive {
      &-item {
        background-size: 80%;
        background-position: top;
        background-repeat: no-repeat;
      }
    }

    .tile__text-section {
      bottom: 0;
      min-height: 80px;
    }
  }
}
</style>


