<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { generateToken } from 'src/modules/recaptcha_v3_module.js'
import { get, range, intersection, isNil } from "lodash";


export default {
  mixins: [fandomBasicMixin, interactionMixin],
  props: {
    formUrl: {
      type: String,
      default: "/upload_simple_form"
    },
    ugcContentName: {
      type: String,
      default: ""
    },
    ugcContentPreview: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      lang: globalState.lang,
      successMessage: this.ft('globals.form.success'),
      form: {
        success: false,
        errors: [],
        loading: false,
        progress: 0,
        data: {},
        directUploads: {}
      },
      contentNameToField: {}
    };
  },
  methods: {
    getYearsSelectValues () {
      const currentYear = (new Date()).getFullYear();
      return range(1900, currentYear);
    },
    getButtonTextFormatted () {
      if (this.form.loading) {
        return this.ft("globals.form.loading");
      } else {
        return this.content.button_text ? this.content.button_text : this.ft("globals.form.send");
      }
    },
    getFieldInputName(fieldName) {
      return `user_upload_interaction[${fieldName}]`;
    },
    getFieldInputId(fieldName) {
      return `field-${fieldName}`;
    },
    getFieldPlaceholder(field) {
      return (typeof field.placeholder == "object") ? field.placeholder[globalState.lang] : field.placeholder;
    },
    getFieldLabel(field) {
      return (typeof field.label == "object") ? field.label[globalState.lang] : field.label;
    },
    getFieldTitle(field) {
      return (typeof field.title == "object") ? field.title[globalState.lang] : field.title;
    },
    getFieldDescription(field) {
      return (typeof field.description == "object") ? field.description[globalState.lang] : field.description;
    },
    onAttachmentChange(event, field) {
      Vue.set(this.form.data, field.name, event.target.files[0]);
    },
    initDirectUpload(event, field) {
      // More files can be uploaded at the same time: this case is not covered
      //const file = event.target.files[0];

      const tmpFile = event.target.files[0];
      const file = new File([tmpFile], `${tmpFile.name.replace(/[^a-zA-Z0-9\._]/g, '_')}`, {type: tmpFile.type});

      if (file) {
        if (file.size > (1024 * 1024) * 100) {
          this.form.errors.push(this.ft("globals.form.file_too_big"));
          return false;
        } else {
          Vue.set(this.form, "errors", []);
        }
      } else {
        Vue.set(this.form, "errors", []);
        return false;
      }

      Fandom.ajax({
        url: '/api/v5/get_aws_presigned_url',
        method: "GET",
        data: {
          filename: file.name
        },
        success: (data) => {
          // set direct upload info for later use
          const directUploadData = {
            signed_url: data.signed_url,
            file: file,
            name: file.name,
            loading: false,
            progress: 0
          }

          Vue.set(this.form.directUploads, field.name, directUploadData);
        }
      })

    },
    directUpload(file, url, fieldName) {
      Vue.set(this.form.directUploads[fieldName], "loading", true);
      let xhr = new XMLHttpRequest();

      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          Vue.set(this.form.directUploads[fieldName], "progress", (evt.loaded / evt.total * 100).toFixed(2));
        }
      }, false);

      const result = new Promise((resolve, reject) => {
        xhr.onload = () => {
          Vue.set(this.form.data, fieldName, JSON.stringify({
            "s3_url": url.split("?")[0],
            "filename": file.name
          }));
          Vue.set(this.form.directUploads[fieldName], "loading", false);
          resolve();
        };
      })

      xhr.open("PUT", url);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "no-cors");
      xhr.send(file);

      return result;
    },
    submitSimpleForm(event) {
      if (!this.form.loading) {
        this.form.success = false;
        this.form.loading = true;
        this.form.errors = null;
        const promises = [];
        Object.keys(this.form.directUploads).forEach(key => {
          const uploadData = this.form.directUploads[key];
          promises.push(this.directUpload(uploadData.file, uploadData.signed_url, key));
        });
        Promise.allSettled(promises).then((_) => {
          let submitForm = (token) => {
            const data = this.getFormData();
            if (token)
              data.append("recaptcha_enterprise_token", token);

            Fandom.ajax({
                async: true,
                processData: false,
                contentType: false,
                method: "POST",
                url: this.formUrl,
                data: data,
                xhr: () => {
                  const xhr = new window.XMLHttpRequest();
                  xhr.upload.addEventListener("progress", (evt) => {
                    if (evt.lengthComputable) {
                      const directUploadsLength = Object.keys(this.form.directUploads).length;
                      if (directUploadsLength > 0) {
                        const progress = evt.loaded / evt.total * 100;
                        this.form.progress = progress * 1 / (directUploadsLength + 1) // +1 represents the submit progress of the form
                      } else {
                        this.form.progress = evt.loaded / evt.total * 100;
                      }
                    }
                  }, false);
                  return xhr;
                },
                success: (data) => {
                  this.form.success = data.success;
                  if (this.form.success) {
                    Vue.set(this.form, "directUploads", {});
                    this.form.progress = 0;

                    if (Fandom.exists(this.content.thank_you_page)) {
                      const thankYouUrl = this.content.thank_you_page.hasOwnProperty("url") ? this.content.thank_you_page.url : this.content.thank_you_page;
                      this.successMessage = null;
                      window.location.href = Fandom.applyContextToUrl(thankYouUrl);
                    } else if (!this.content.precompile) {
                      this.resetFormData();
                    }
                  } else {
                    this.form.errors = data.errors;
                  }
                },
                error: (err) => {
                  this.form.errors = err.responseJSON.errors;
                }
              },
              () => {
                this.progress = null;
                this.form.loading = false;
              }
            );
          }
          if (globalState.pageModel.aux.recaptcha_enterprise) {
            generateToken(submitForm, "submit");
          } else {
            submitForm();
          }
        })
      }
    },
    mayPrecompileFormData() {
      const successHook = data => {
        this.formFields.forEach(field => {
          const value = data[field.name];
          if (Fandom.exists(value)) {
            switch (field.type) {
              case "birthdate":
                let date = new Date(value);
                date = { year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate() };

                ["day", "month", "year"].forEach(prefix => {
                  Vue.set(this.form.data, `${prefix}_${field.name}`, date[prefix]);
                });
                break;
              case "multiple-choice":
                const choices = JSON.parse(value);
                Vue.set(this.form.data, field.name, choices);
                break;
              case "dates-list":
                const dates = JSON.parse(value);
                Vue.set(this.form.data, field.name, dates);
                break;
              case "attachment":
                if (value && value.url) {
                  fetch(value.url, {
                    mode: 'no-cors'
                  })
                    .then(response => response.blob())
                    .then(blob => new File([blob], `${value.url.split("/").pop().split("?")[0]}`, {
                      type: blob.type
                    }))
                    .then(file => {
                      Vue.set(this.form.data, field.name, file);
                    })
                }
                break;
              case "datetime-local":
                Vue.set(this.form.data, field.name, (value || "").split(".")[0]);
                break;
              default:
                Vue.set(this.form.data, field.name, value);
            }
          }
        });
      }
      if (this.ugcContentName) {
        Fandom.getContents(this.ugcContentName, {
          layout: "default",
          include_children: false,
          preview: this.ugcContentPreview
        }, (data) => {
          const mainContent = data.name_to_content[data.main_content_name];
          if (this.formFields.find(f => f.name === "level"))
            Vue.set(this.form.data, "level", mainContent.parent_names.find(p => p.startsWith("course-level")));
          successHook(mainContent)
        })
      } else if (this.content.precompile) {
        Fandom.ajax({
          url: "/api/v5/get_submission_init_data",
          method: "GET",
          data: {
            interaction_id: this.content.interaction.id,
            container_content_name: this.containerContent.name
          },
          success: successHook
        });
      }
    },
    resetFormData() {
      this.form.data = {};
      this.$refs.form.reset();
      this.mayInitializeFields();
    },
    getFormData() {
      const formData = new FormData();
      this.formFields.forEach(field => {
        if (field.type == "birthdate") {
          const data = { year: null, month: null, day: null };
          ["day", "month", "year"].forEach(prefix => {
            data[prefix] = this.form.data[`${prefix}_${field.name}`];
          });
          if (data.year && data.month && data.day) {
            const month = ("0" + data.month).slice(-2);
            const day = ("0" + data.day).slice(-2);
            formData.append(this.getFieldInputName(field.name), `${data.year}/${month}/${day}`);
          }
        } else if (field.type === "autocomplete" || field.type === "multiple-choice" || field.type === "dates-list") {
          const formDataValue = JSON.stringify(this.form.data[field.name]);
          formData.append(this.getFieldInputName(field.name), formDataValue);
        } else {
          const formDataValue = this.form.data[field.name];
          if (!isNil(formDataValue) && field.type != "textseparator") {
            formData.append(this.getFieldInputName(field.name), formDataValue);
          }
        }
      });

      formData.append("interaction_id", this.content.interaction.id);
      formData.append("content_id", this.containerContent.id);

      return formData;
    },
    mayInitializeFields() {
      this.formFields.forEach((field) => {
        switch (field.type) {
          case "hidden":
            Vue.set(this.form.data, field.name, field.value);
            break;
          case "dropdown": // Dropdown required default empty string
            Vue.set(this.form.data, field.name, "");
            break;
          case "birthdate": // Dropdown required default empty string
            ["day", "month", "year"].forEach(prefix => {
              Vue.set(this.form.data, `${prefix}_${field.name}`, "");
            });
            break;
          case "content":
            Vue.set(this.contentNameToField, field.content_name, []);
            Vue.set(this.form.data, field.name, ""); // Dropdown required default empty string
            break;
          case "multiple-choice":
            Vue.set(this.form.data, field.name, []);
            break;
          case "dates-list":
            Vue.set(this.form.data, field.name, [{value: null}]);
            break;
          default:
            // nothing to do
        }
      });

      if (!!Object.keys(this.contentNameToField).length) {
        Fandom.post({
            url: "/api/v5/get_submission_contents_from_content_fields",
            data: {
              layout: "system-refs",
              layout_extra_field_name: "$empty",
              content_filtering: [Object.keys(this.contentNameToField)]
            },
            success: (data) => {
              data.name_to_content[data.main_content_name].children.forEach(contentName => {
                const content = data.name_to_content[contentName];
                intersection(content.parent_names, Object.keys(this.contentNameToField)).forEach(parentContent => {
                  this.contentNameToField[parentContent].push(content);
                });
              });
            }
          }
        );
      }
    },
    toggleMultipleChoice(field, choice) {
      Vue.set(this.form.data, field.name, get(this, ['form','data',field.name], []));
      const index = this.form.data[field.name].findIndex(c => c.id == choice.id)
      if (index > -1) {
        this.form.data[field.name].splice(index, 1);
      } else {
        this.form.data[field.name].push(choice);
      }
    },
    getMultipleChoiceText(choice) {
      return get(choice, ["value", globalState.lang]) ?? get(choice, [globalState.lang]) ?? get(choice, ["value"]) ?? choice;
    },
    addDate(field) {
      Vue.set(this.form.data, field.name, [{value: null}, ...get(this.form.data, [field.name], [{value: null}])]);
    },
    removeDate(field, index) {
      const oldValue = get(this.form.data, [field.name], [{value: null}]);
      Vue.set(this.form.data, field.name, [...oldValue.slice(0,index), ...oldValue.slice(index + 1)]);
    }
  },
  mounted() {
    this.mayInitializeFields();
    this.mayPrecompileFormData();
  },
  computed: {
    isFormLoading() {
      return this.form.loading;
    },
    globalProgress() {
      if (this.form.success) {
        return 100;
      } else {
        const directUploadsLength = Object.keys(this.form.directUploads).length;
        let result = 0;

        Object.keys(this.form.directUploads).forEach(key => {
          const progress = this.form.directUploads[key].progress;
          result += (progress * 1 / (directUploadsLength + 1)) // +1 represents the submit progress of the form
        });
        result += this.form.progress;

        return parseInt(result);
      }
    },
    formFields() {
      const fields = JSON.parse(this.content.form_fields).fields || [];
      return fields.filter(f => {
        if (!!f.show_if) {
          const key = f.show_if.split(":")[0];
          let value = f.show_if.split(":")[1];
          if (value.toLowerCase() === "false") value = false;
          else if (value.toLowerCase() === "true") value = true;
          return this.form.data[key] === value || this.form.data[key] === undefined;
        } else {
          return true;
        }
      });
    }
  }
};
</script>

<template>
  <div generic-form-component>
    <form ref="form" class="d-flex flex-column" @submit.prevent="submitSimpleForm($event)" :action="formUrl" method="post" enctype="multipart/form-data">
      <div v-for="(field, index) in formFields" :key="index">
        <div v-if="field.type == 'dropdown'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="custom-select-outer">
            <select :disabled="field.disabled" class="custom-select" :required="field.required" v-model="form.data[field.name]">
              <option value="" disabled selected>{{getFieldPlaceholder(field)}}</option>
              <option v-for="(option, optionIndex) in field.options" :key="`option-${optionIndex}`" :value="option.value">{{option.label}}</option>
            </select>
          </div>
        </div>
        <div v-else-if="field.type == 'content'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="custom-select-outer">
            <select :disabled="field.disabled" class="custom-select" :required="field.required" v-model="form.data[field.name]">
              <option value="" disabled selected>{{getFieldPlaceholder(field)}}</option>
              <option v-for="(option, optionIndex) in contentNameToField[field.content_name]" :key="`option-${optionIndex}`" :value="option.name">{{option.title}}</option>
            </select>
          </div>
        </div>
        <div v-else-if="field.type == 'textfield' || field.type == 'fiscalcode'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <input :disabled="field.disabled" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" type="text" :required="field.required">
        </div>
        <div v-else-if="field.type == 'phone'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <input :disabled="field.disabled" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" type="number" :required="field.required">
        </div>
        <div v-else-if="field.type == 'textarea'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <textarea
            :disabled="field.disabled" 
            v-model="form.data[field.name]"
            :placeholder="getFieldPlaceholder(field)"
            class="form-control"
            :class="field.class"
            rows="5"
            :required="field.required"
            :maxlength="field.maxlength || 1000"
          ></textarea>
        </div>
        <div v-else-if="field.type == 'radio'">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <div class="form-group">
            <span class="radio-option mx-2" v-for="(option, optionIndex) in field.options" :key="`option-${optionIndex}`">
              <input :disabled="field.disabled" type="radio" :value="option.value" v-model="form.data[field.name]" class="mr-2">
              <label>{{option.label}}</label>
            </span>
          </div>
        </div>
        <div v-else-if="field.type == 'checkbox'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <div class="form-check">
            <input :disabled="field.disabled" type="checkbox" class="form-check-input" v-model="form.data[field.name]" :required="field.required">
            <label class="form-check-label"><span v-html="getFieldLabel(field)"></span> <span v-if="field.required">*</span></label>
          </div>
        </div>
        <div v-else-if="field.type == 'attachment'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)" class="form-label">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="custom-file">
            <input :disabled="field.disabled" type="file" class="custom-file-input" @change="onAttachmentChange($event, field)" :required="(field.required && !form.data[field.name])" :lang="lang">
            <label class="custom-file-label">{{getFieldPlaceholder(field)}}</label>
            <div class="form-text text-muted pt-2" v-if="form.data[field.name] && form.data[field.name].name">
              <i class="fas fa-check-circle text-green-apple"></i> {{form.data[field.name].name}}
            </div>
          </div>
        </div>
        <div v-else-if="field.type == 'directupload'" class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)" class="form-label">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="custom-file">
            <input :disabled="field.disabled" type="file" class="custom-file-input" :accept="field.accept" @change="initDirectUpload($event, field)" :required="field.required" :lang="lang">
            <label class="custom-file-label">{{getFieldPlaceholder(field)}}</label>
            <div class="form-text pt-2 text-muted" v-if="form.directUploads[field.name] && form.directUploads[field.name].name">
              <i class="fas fa-check-circle text-green-apple"></i> {{form.directUploads[field.name].name}}
            </div>
          </div>
        </div>
        <div v-else-if="field.type == 'email'" class="form-group">
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <input :disabled="field.disabled" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" type="email" :required="field.required">
        </div>
        <div v-else-if="field.type == 'birthdate'" class="form-group">
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="row">
            <div class="col-12 col-sm-4">
              <div class="custom-select-outer">
                <select :disabled="field.disabled" class="custom-select" v-model="form.data[`day_${field.name}`]" :required="field.required">
                  <option value="" disabled selected>{{ft("globals.form.day")}}</option>
                  <option v-for="day in 31" :value="day" :key="`day-${day}`">{{("0" + day).slice(-2)}}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="custom-select-outer">
                <select :disabled="field.disabled" class="custom-select" v-model="form.data[`month_${field.name}`]" :required="field.required">
                  <option value="" disabled selected>{{ft("globals.form.month")}}</option>
                  <option v-for="month in 12" :value="month" :key="`month-${month}`">{{("0" + month).slice(-2)}}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="custom-select-outer">
                <select :disabled="field.disabled" class="custom-select" v-model="form.data[`year_${field.name}`]" :required="field.required">
                  <option value="" disabled selected>{{ft("globals.form.year")}}</option>
                  <option v-for="year in getYearsSelectValues()" :value="year" :key="`year-${year}`">{{year}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="field.type == 'multiple-choice'" class="form-group">
          <label v-if="getFieldLabel(field)" class="form-label">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="d-flex flex-wrap">
            <div class="form-group form-check mr-3 mb-2" v-for="(choice, index) in field.choices" :key="`choice-${index}`">
              <input
                :disabled="field.disabled"
                type="checkbox"
                class="form-check-input mt-1"
                :id="`choice-${field.name}-${index}`"
                @click="toggleMultipleChoice(field, choice)"
                :checked="form.data[field.name] instanceof Array && form.data[field.name].find(c => choice.id == c.id)"
              >
              <label class="form-check-label" v-html="getMultipleChoiceText(choice)"></label>
            </div>
          </div>
        </div>
        <div v-else-if="field.type == 'dates-list'" class="form-group">
          <label v-if="getFieldLabel(field)" class="form-label">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <div class="input-group mb-3" v-for="(date, dateIndex) in (form.data[field.name] || [])">
            <input :disabled="field.disabled" :required="field.required" type="datetime-local" v-model="date.value" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" class="form-control" :placeholder="getFieldPlaceholder(field)">
            <div class="input-group-append" v-if="!dateIndex">
              <button class="btn btn-gray-light" type="button" @click="addDate(field)">{{ft('globals.add_date')}}</button>
            </div>
            <div class="input-group-append" v-else>
              <button class="btn btn-danger" type="button" @click="removeDate(field, dateIndex)">{{ft('globals.remove_date')}}</button>
            </div>
          </div>
        </div>
        <div v-else class="form-group">
          <div class="field-title mb-3" v-if="getFieldTitle(field)" v-html="getFieldTitle(field)"></div>
          <div class="field-description mb-3" v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></div>
          <label v-if="getFieldLabel(field)">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <input :disabled="field.disabled" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" :pattern="field.pattern" :type="field.type" :required="field.required">
        </div>
        <p v-if="field.type == 'textseparator'">{{getFieldLabel(field)}}</p>
      </div>

      <div class="row" v-if="content.privacy_disclaimer">
        <div class="col-12 col-md-8">
          <small class="form-text" v-html="content.privacy_disclaimer"></small>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mx-auto">
          <button type="submit" class="btn w-100 mb-3 text-uppercase" :class="[getPrimaryButtonClass(content.button_class), {'disabled': form.loading}]" :disabled="form.loading">{{getButtonTextFormatted()}}</button>
        </div>
      </div>

      <div class="progress" v-if="content.show_progress && globalProgress">
        <div class="progress-bar" role="progressbar" :aria-valuenow="globalProgress" aria-valuemin="0" aria-valuemax="100" :style="{ width: globalProgress + '%' }">
          <span class="progress-bar-text">{{ft("globals.form.uploading", {percentage: globalProgress})}}</span>
        </div>
      </div>
      <form-feedback :errors="form.errors" :success="form.success" :success-message="successMessage"></form-feedback>
    </form>
  </div>
</template>

<style lang="scss" scoped>
  .custom-select-outer {
    width: 100%;
  }

  .progress-bar-text {
    position: absolute;
    left: 0;
    right: 0;
  }

  .progress {
    height: 2rem;
  }

  .form-group {
    margin-bottom: 2rem;

    .form-check-input {
      margin-top: 0.4rem;
    }

    .input-group-append button.btn-gray-light {
      border: 1px solid #ced4da;
    }
  }

  textarea {
    &.no-resize {
      resize: none;
    }
  }

  ::v-deep {
    .feedback-error-title {
      font-weight: bold;
    }
  }
</style>
