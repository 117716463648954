<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="d-flex flex-column justify-content-center align-items-center text-center py-3" tile-ambassador>
    <component :is="tileUrl ? 'a' : 'div'" :href="tileUrl">
      <img v-if="content.avatar" :src="content.avatar.url" :alt="content.avatar.alt" class="rounded-circle"/>
    </component>
    <div v-if="content.title" class="title h6 mt-4 mb-0">{{content.title}}</div>
    <div class="d-flex social-icons mt-2" v-if="content.social_icons">
      <a
        v-for="(link, index) in content.social_icons"
        :key="`social-icon-${index}`"
        :href="link.url.url"
        class="link-dark"
        :target="getTarget(link.url)"
      >
        <i class="mx-2" :class="link.icon"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    tileUrl() {
      return this.content.slug ? this.content.slug : false;
    },
  }
};
</script>

<style lang="scss">
[tile-ambassador] {
  img {
    width: 80%;
    height: auto;
    max-width: 300px;
  }

  .title {
    font-family: Gotham;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    text-transform: uppercase;
  }
}
</style>
