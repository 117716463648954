<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    facebookUrl: Fandom.facebookUrl,
    getButtonClass: Fandom.getButtonClass,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getSecondaryButtonClass: Fandom.getSecondaryButtonClass,
    handleButtonClick(button) {
      if (button.url === "$video") {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      } else if (button.url === "$modal-ask-kenwood") {
        Fandom.emit("show-modal", {name: "modal-form"});
      }
    },
    getButtonUrl(button) {
      return ["$video", "$modal-ask-kenwood"].includes(button.url) ? "javascript:void(0)" : button.url;
    }
  },
  computed: {
    iconStyle() {
      if (this.content.icon.url) {
        return {
          width: get(this.content, "icon_width", "6rem")
        }
      } else {
        return {
          fontSize: get(this.content, "icon_width", "3rem")
        }
      }
    },
    iconClass() {
      if (!this.content.icon.url) {
        return `d-inline-block text-primary ki ki-${this.content.icon}`
      }
    },
    textAlign() {
      // Allowed values: left, right and center.
      return (this.content.text_align) ? `text-${this.content.text_align}` : null;
    },
    hasShareInteraction() {
      return get(this, "content.share.interaction.type", false) === "Share";
    },
    category() {
      return Fandom.exists(this.content.category) ? this.content.category : this.content.miniformat_title;
    },
    buttonsRequired() {
      return this.content.button || this.content.buttons;
    },
    mainClasses() {
      return [
        this.textAlign
      ];
    }
  }
};
</script>

<template>
  <div :class="mainClasses" text-section-component>
    <component
      :is="content.icon.url ? 'img' : 'i'" 
      v-if="content.icon"
      :class="iconClass"
      class="icon my-4"
      :alt="content.icon.alt"
      :src="content.icon.url"
      :style="iconStyle"
    ></component>
    <p v-if="category" class="category d-inline-block px-3 py-1 text-uppercase small">{{category}}</p>
    <component v-if="content.title" :class="content.title_class" :is="content.title_tag || 'h2'" :style="{'color': content.text_color}" title>
      {{content.title}}
    </component>
    <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" :class="content.subtitle_class" :style="{'color': `${content.text_color}`}" subtitle>{{content.subtitle}}</component>
    <component :is="content.description_tag || 'div'" :class="content.description_class" v-if="content.description" :style="{'color': `${content.text_color}`}" v-html="content.description" description></component>
    <div v-if="buttonsRequired" class="align-btn-group">
      <a 
        v-if="content.button" 
        class="btn shadow-none m-2 my-2 d-inline-flex" 
        :class="getPrimaryButtonClass(content.button_class, content.theme)" 
        :target="getTarget(content.button)"
        :rel="getRelNoFollow(content.button)"
        :href="getButtonUrl(content.button)"
        @click="handleButtonClick(content.button)"
      >
        <i v-if="content.button_icon" class="pr-2" :class="content.button_icon"></i>
        {{content.button.title}}
      </a>
      <template v-if="content.buttons">
        <a 
          class="btn shadow-none m-2" 
          v-for="(buttonRecord, index) in content.buttons" 
          :key="index" 
          :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
          :target="getTarget(buttonRecord.button)"
          :rel="getRelNoFollow(buttonRecord.button)"
          :href="getButtonUrl(buttonRecord.button)"
          @click="handleButtonClick(buttonRecord.button)"
        >
          {{buttonRecord.button.title}}
        </a>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[text-section-component] {
  .category {
    //background-color: $dark;
    //color: $light;
    background-color: $primary;
    color: $light;
  }

  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .icon {
    max-width: 100%;
  }
}
</style>
