<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { kebabCase } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  props: ["item"],
  data: function() {
    return { };
  },
  mounted() {
    Vue.nextTick(() => {
      disableBodyScroll(this.$el);
    });
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    getTarget: Fandom.getTarget,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    slugify: kebabCase
  },
  computed: { },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
};
</script>

<template>
  <div class="position-fixed bg-white pr-2 pl-3"  sidebar-item-component>
    <div class="px-3 py-4 d-flex justify-content-between border-bottom border-gray-primary w-100" @click="$emit('sidebar-item-close')">
      <div class="mx-3 my-1 d-flex align-items-center" :class="`text-${item.color ? item.color : 'pink'}`">
        <i class="fal fa-chevron-left text-lg mr-3"></i>
        <span class="text-uppercase">{{ item.url.title }}</span>
      </div>
    </div>
    <div v-for="(subitem, index) in item.menu" :key="`${index}-subitem`">
      <div v-show="subitem.title" class="px-3 py-4 border-bottom border-gray-primary w-100">
        <div class="mx-3 my-1 text-uppercase subitem">
          <a 
            :href="`#item-${slugify(item.url.title)}-${slugify(subitem.title)}`" 
            data-toggle="collapse"
            class="collapsed d-flex justify-content-between align-items-center text-dark"
          >
            {{ subitem.title }} 
            <i v-if="subitem.menu" class="fal fa-chevron-right fa-lg ml-3"></i>
          </a>
        </div>
      </div>
      <div :id="`item-${slugify(item.url.title)}-${slugify(subitem.title)}`" class="collapse" :class="{'show': !subitem.title}">
        <div class="p-3 border-bottom border-gray-primary w-100">
          <div v-for="(link, i) in subitem.menu" class="py-1" :key="`${i}-link`">
            <a 
              :href="link.url.url" 
              class="link-gray-dark text-capitalize ml-3" 
              :target="getTarget(link.url)"
              :rel="getRelNoFollow(link.url)"
            >
              {{link.url.title}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3 py-2 border-bottom border-gray-primary w-100" v-if="item.corner_url">
      <div class="mx-3 my-1 text-uppercase subitem d-flex justify-content-between align-items-center">
        <a 
          :href="item.corner_url.url" 
          class="link-dark deco-none" 
          :rel="getRelNoFollow(item.corner_url)"
        >
          {{ item.corner_url.title }} 
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

[sidebar-item-component] {
  z-index: 1;
  top: $navbar-height;
  bottom: 0;
  width: 80vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - #{$navbar-height});

  .subitem {
    & > a:not(.collapsed),
    & > a:not(.collapsed) > i {
      transition: all .25s;
    }

    & > a:not(.collapsed) > i {
      transform: rotate(90deg);
    }
  }

  .url-lg {
    font-family: $bressay-font;
  }
}
</style>
