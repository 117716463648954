<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      relatedContent: [],
      profilerCompleted: false
    };
  },
  mounted() {
    Fandom.onEvent("profiler:update", this.initProfilerCompleted);
    this.initProfilerCompleted();
  },
  methods: {
    getProfilerContent() {
      const names = this.content.items.flatMap((item) => this.getUserItems(item.extra_field_name));
      Fandom.post({
        data: {contents_names: names, limit: 100, layout: "default"},
        url: "/api/v5/get_contents_by_names",
        success: (data) => {
          Vue.set(this, "relatedContent", data.name_to_content);
        }
      });
    },
    getUserItems(extraFieldName) {
      return get(this.pageModel.user, [extraFieldName], []);
    },
    editUserPreferences() {
      Fandom.emit('show-modal', {name: "modal-profiler"});
    },
    getContent(name) {
      return this.relatedContent[name] || ''
    },
    initProfilerCompleted() {
      this.profilerCompleted = this.content.items.reduce((acc, item) => {
        return acc && this.getUserItems(item.extra_field_name).length>0
      }, true);

      if (this.profilerCompleted) {
        this.getProfilerContent();
      }
    }
  },
  computed: {
    componentStyle() {
      return {
        "background-color": this.content.background_color
      };
    },
    buttonClass() {
      return get(this.content, "button_class", "btn-primary");
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" user-preferences-component>
    <div class="w-100" user-edit-component :style="componentStyle">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-8 col-sm-6 my-5">
            <h1 class="mb-4 mb-md-5 text-center" v-if="content.title">{{content.title}}</h1>
            <template v-if="profilerCompleted && Object.keys(relatedContent).length>0">
              <div v-for="item in content.items" :key="`profiler-info-${item.$id}`" class="mb-4 mb-md-5">
                <div class="text-uppercase mb-2">{{item.title}}</div>
                <div v-if="!item.type || item.type === 'label'" class="d-flex flex-wrap mx-n1">
                  <div v-for="userItem in getUserItems(item.extra_field_name)" class="mx-1 my-2 px-4 py-2 bg-white rounded-pill text-uppercase label" :key="`profiler-info-${userItem}`">
                    {{getContent(userItem).title}}
                  </div>
                </div>
                <div v-else-if="item.type === 'textual'">
                  <div v-for="(userItem, index) in getUserItems(item.extra_field_name)" :key="`profiler-info-${userItem}`">
                    <h4>
                      <i>
                        <span v-if="getUserItems(item.extra_field_name).length > 1">{{index + 1}}. </span> {{getContent(userItem).title}}
                      </i>
                    </h4>
                  </div>
                </div>
              </div>
            </template>
            <div v-else-if="!profilerCompleted" class="mb-4 mb-md-5">
              <p class="m-0" v-html="content.empty_preferences"></p>
            </div>
            <div class="d-flex justify-content-center my-4 my-md-5">
              <button class="btn" :class="buttonClass" @click="editUserPreferences">{{content.button_text}}</button>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[user-preferences-component] {
  .label {
    @include font-size(.75rem);
    font-weight: 500;
  }
}
</style>