<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    tileLayout: String
  },
  data() {
    return {
      tileSize: false,
      getContentsParams: {
        limit: (this.content.limit || 8),
        content_filtering: []
      }
    };
  },
  computed: {
    children() {
      return this.content.children;
    },
    desktopColumn() {
      const width = get(this, ["content", "width"], 12);
      return `col-lg-${width}`;
    }
  },
  mounted() {
    Fandom.onEvent("grid-filter-apply", (data) => {
      if (data.handlerContents.indexOf(this.content.name) > -1) {
        this.getContentsParams.content_filtering = data.contentFiltering;
        Fandom.getContents(this.content.name, this.getContentsParams, () => {}, false);
      }
    });
    Vue.nextTick(() => {
      if (this.content.column_count) {
        this.$el.style.setProperty("--grid-column-count", this.content.column_count);
      }
    });
  },
  methods: {
    childLayout(child) {
      return this.getContent(child).layout;
    },
    childTileSize(childName) {
      const child = this.getContent(childName);
      return Fandom.exists(child.isotope_size) ? child.isotope_size : "content";
    },
    isotopeClass(child) {
      const size = this.tileSize || this.childTileSize(child);
      return `item--${size}`;
    },
    appendContents() {
      Fandom.appendContents(this.content.name, null, null, this.getContentsParams);
    }
  },
};
</script>

<template>
  <div v-if="isContentVisibleToUser()" v-easyadmin="easyadminId" :class="[contentType, paddingY]" :id="anchorId" class="themed-item" :style="theme" grid-isotope-component>
    <div class="container">
      <div v-if="content.children && content.children.length == 0" class="row">
        <div class="col-12"><p>{{ft("globals.no_content")}}</p></div>
      </div>
      <div class="row justify-content-center" v-else>
        <div class="col-12 px-sm-0 grid" :class="desktopColumn">
          <div v-for="child in children" :key="`item-${getContent(child).name}`" :class="isotopeClass(child)" class="item position-relative">
            <component :is="tileLayout || childLayout(child)" :content="getContent(child)"></component>
          </div>
        </div>
      </div>
      <div v-if="content.has_more_children" class="row pt-4">
        <div class="col-12 d-flex justify-content-center">
          <button class="btn btn-primary" @click="appendContents()">{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[grid-isotope-component] {
  overflow-x: hidden;
  
  .container {
    @include media-breakpoint-down(sm) {
      padding: 0 !important;
    }
  }

  .grid {
    grid-template-columns: 1fr;
    grid-auto-flow: row dense;
    grid-auto-rows: 1fr;
    grid-gap: $isotope-grid-gap-row $isotope-grid-gap-column;
    display: grid;
  }

  .item {
    padding-bottom: 100%;
  }

  @include media-breakpoint-up(md) {
    .grid {
      grid-template-columns: repeat(var(--grid-column-count, 4), 1fr);
    }

    .item {
      padding-bottom: 50%;
    }

    .item--content {
      grid-row: span 1;
      grid-column: span 1;
      padding-bottom: 100%;
    }

    .item--widecontent {
      grid-row: span 1;
      grid-column: span 2;
    }

    .item--ultrawidecontent {
      grid-row: span 1;
      grid-column: span 4;
    }

    .item--highcontent {
      grid-row: span 2;
      grid-column: span 1;
    }

    .item--largesquarecontent {
      grid-row: span 2;
      grid-column: span 2;
      padding-bottom: 100%;
    }
  }
}
</style>
