<template>
  <a
    href="#"
    class="filter-tag btn btn-outline-custom rounded-pill text-capitalize m-2 shadow-none d-inline-flex align-items-center"
    @click.prevent="toggleFilter"
    :class="{'active': filter.isActive}"
  >
    <i v-if="filter.icon" class="ki pr-3" :class="`ki-${filter.icon}`"></i>
    <span>{{ filter.title }}</span>
  </a>
</template>

<script>
export default {
  props: ["value"],
  data: function() {
    return {
      filter: {...this.value}
    }
  },
  methods: {
    toggleFilter() {
      this.filter.isActive = !this.filter.isActive;
      this.$emit('input', this.filter);
    }
  },
  watch: {
    "value.isActive"(newVal, oldVal) {
      if (newVal!==oldVal) {
        this.filter = {...this.value};
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-tag {
  @include media-breakpoint-down(sm) {
    font-size: .875rem;
    padding: .5rem .8rem;
    margin: .25rem !important;
  }
}
</style>