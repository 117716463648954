<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl
  },
  data() {
    return {};
  },
  computed: {
    tileDescription() {
      return this.content.short_description || this.content.description
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="w-100" tile-recipe>
    <div class="card border-0 bg-transparent">
      <div class="card-body p-0">
        <a :href="content.slug">
          <div class="ar-3by4 h-100 bg-cover-center w-100" :style="{'background-image': getTileThumbnailUrl('thumbnail')}"></div>
        </a>
      </div>
      <div class="card-footer bg-transparent py-3 px-0">
        <p class="text-dark mb-0 line-clamp" v-if="tileDescription" v-html="tileDescription"></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[tile-recipe] {
  $title-height: 16%;

  .card {
    &-footer {
      .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
