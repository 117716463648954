<script>
import Flickity from "flickity";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      carousel: false
    };
  },
  mounted() {
    if (this.content.items.length && !this.carouselDisabled) {
      Vue.nextTick(() => {
        this.initCarousel();
      })
    }
  },
  methods: {
    isActive(item) {
      return this.mainContent.name.includes(item.url.content);
    },
    initCarousel() {
      if (!this.carousel) {
        this.carousel = new Flickity(this.$refs["menu"], {
          draggable: ">1",
          prevNextButtons: ">1",
          cellAlign: "left",
          wrapAround: false,
          pageDots: false,
          groupCells: true,
          arrowShape: { 
            x0: 10,
            x1: 60, y1: 50,
            x2: 60, y2: 40,
            x3: 20
          },
        });
      }

      this.carousel.on('ready', () => {
        this.carousel.resize();
      });
    },
    getUrlObj(item){
      return item.url;
    }
  },
  computed: {
    carouselDisabled() {
      return this.content.disable_carousel || false;
    },
    menuClass() {
      return this.carouselDisabled ? 'flickity-carousel--disabled' : 'flickity-carousel--enabled';
    }
  }
}
</script>

<template>
  <div
  v-easyadmin="easyadminId"
  icon-menu
  :class="contentType"
  v-if="content.items.length > 0"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 px-0">
          <div ref="menu" class="flickity-carousel" :class="menuClass">
            <a
              v-for="(item, index) in content.items"
              class="d-flex flex-column align-items-center text-center menu-url py-4" 
              :href="applyContextToUrl(item.url.url)"
              :class="{'active': isActive(item)}"
              :title="item.url.title"
              :key="index"
              :target="getTarget(getUrlObj(item))"
              >
              
              <img width="48px" v-if="item.icon && item.icon.url" :src="item.icon.url" :alt="item.url.title">
              <i v-else class="ki" :class="`ki-${item.font_icon}`"></i>
              <span class="menu-title d-flex align-items-center text-uppercase mt-3">{{item.url.title}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[icon-menu] {
  $transition-time: .5s;

  ::v-deep .flickity {
    &-carousel {
      @include media-breakpoint-down(md) {
        .flickity-prev-next-button {
          display: none;
        }
      }

      &--disabled {
        display: flex;
        justify-content: center;
       
        @include media-breakpoint-down(sm) {
          .menu-url {
            flex: 1;
          }
        }
      }
    }

    &-button:disabled {
      display: none;
    }
  }

  .menu-url {
    fill: none;
    stroke: currentColor;
    color: $gray-600;
    transition: stroke $transition-time ease-in-out, color $transition-time ease-in-out;
    text-decoration: none;
    width: 40%;

    @include media-breakpoint-up(sm) {
      width: 20%;
    }

    @include media-breakpoint-up(md) {
      width: 25%;
    }

    @include media-breakpoint-up(lg) {
      width: 15%;
    }

    @include media-breakpoint-up(xl) {
      width: 11.1%;
    }

    @include hover {
      color: var(--black);
    }

    &.active {
      color: var(--black);
    }

    .menu-icon {
      width: 3rem;
      height: 3rem;
      stroke-linejoin: round;
      stroke-linecap: round;
    }

    .ki {
      font-size: 3rem;
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    .menu-title {
      font-size: .75rem;
      white-space: nowrap;
      @include media-breakpoint-down(md) {
        font-size: .50rem;
      }
    }
  }
}
</style>
