<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { map } from "lodash";
import Intersect from "vue-intersect";

export default {
  mixins: [fandomBasicMixin],
  components: { Intersect },
  data: function () {
    return {
      inherents: []
    };
  },
  mounted() {
  },
  methods: {
    headingContent(inherent) {
      return { 
        title: this.getContent(inherent).title, 
        width: 12,
        padding_bottom: 3 
      }
    },
    initInherent() {
      Fandom.post({
        data: { 
          main_content_name: this.containerContent.name, 
          exclude_content_ids: map(globalState.pageModel.name_to_content, c => c["id"])
        },
        url: "/api/v5/get_recipe_inherents_contents",
        success: (data) => {
          Fandom.getContentsCallback(data.main_content_name, data, (data) => {
            this.inherents = Fandom.getContent(data.main_content_name).children;
          })
        }
      });
    }
  }
};
</script>

<template>
  <intersect @enter.once="initInherent()">
    <div recipe-inherents-component>
      <template v-for="(inherent, index) in inherents">
        <template v-if="getContent(inherent).children.length">
          <stripe-text :key="`inherent-${index}`" :content="headingContent(inherent)" :opt-container-content="containerContent"></stripe-text> 
          <component :is="getContent(inherent).layout" :content="getContent(inherent)" :key="getContent(inherent).name" :opt-container-content="containerContent"></component>
        </template>
      </template>
      <div class="py-5 w-100 text-center" v-if="!inherents.length">
        <i class="fad fa-spinner fa-spin text-primary fa-pulse fa-3x"></i>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss">
</style>