<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { set } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function() {
    return {
    };
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    mayToggleVideoPopup() {
      if (Fandom.exists(this.content.play)) {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      }
    }
  },
  computed: {
  }
};
</script>

<template>
  <div
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY]" 
    class="bg-cover-center themed-item"
    v-if="isContentVisibleToUser()"
    :id="anchorId"
    :style="[theme, {
      'background-image': getContentResponsiveImageURL('background_image')
    }]" 
    stripe-media-component
  >
    <div class="container">
      <div class="row">
        <div class="col-12 px-0 media-container" :class="{'media-container--full-width': content.image_full_width}">
          <template v-if="content.play">
            <embed-advanced :content="content" 
                          :opt-container-content="containerContent"
                          ref="embed-advanced"
                          v-if="!content.modal"
            ></embed-advanced>
            <div class="modal-container ar-16by9 bg-cover-center" :style="{'background-image': getContentResponsiveImageURL('image')}" @click="mayToggleVideoPopup()" :class="{'cursor-pointer': content.play}" v-else>
              <div class="position-absolute-center d-flex align-items-center justify-content-center flex-column px-3">
                <i class="fal fa-play-circle fa-4x my-3"></i>
                <text-section :content="{...{text_align: 'center'}, ...content}" :opt-container-content="containerContent"></text-section>
              </div>
            </div>
          </template>
          <template v-else-if="content.image">
            <img v-if="!content.aspect_ratio" class="image" :src="content.image.url" :alt="content.image.alt">
            <div v-else 
              class="bg-cover-center"
              :class="content.aspect_ratio"
              :style="{'background-image': getContentResponsiveImageURL('image')}">
            </div>
            <div class="position-absolute-center d-flex align-items-center justify-content-center p-3">
              <text-section :content="content" :opt-container-content="containerContent"></text-section>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[stripe-media-component] {
  position: relative;
  background-color: var(--theme-background);
  color: var(--theme-color);

  .media-container {
    .image {
      width: 100%;
    }

    &--full-width {
      height: 80vh;
      overflow-x: scroll;
      box-shadow: rgb(222, 222, 222) 0px 0px 50px 0px;

      .image {
        height: 100%;
        width: auto;
      }
    }
  }

  @include media-breakpoint-down(md) {
    /* Forced square rappresentation on mobile */
    .modal-container.ar-16by9 {
      padding-bottom: 100%;

      .fa-8x {
        font-size: 5rem;
      }
    }
  }
}
</style>
