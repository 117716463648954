<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
  },
  computed: { }
};
</script>

<template>
  <div
    option-large
    class="border text-uppercase m-1 cursor-pointer bg-white"
    :class="active ? 'border-primary' : 'border-gray-300'"
    @click="$emit('toggle')"
  >
    <div class="w-100 ar-1by1 position-relative">
      <div class="position-absolute-center d-flex flex-column justify-content-center align-items-center">
        <div class="main-image h-100 position-relative bg-contain-center pt-2" :style="{'background-image': getContentResponsiveImageURL('thumbnail', content)}" v-if="content.thumbnail">
        </div>
        <div class="main-image h-100 position-relative d-flex justify-content-center align-items-center pt-2" v-if="content.icon">
          <i class="ki" :class="[`ki-${content.icon}`, {'text-pink': active}]"></i>
        </div>
        <div class="px-3 py-2" :class="{'text-pink': active}">{{content.title}}</div>
      </div>
      <div class="position-absolute side-square-checked bg-pink d-flex justify-content-center align-items-center" v-if="active">
        <i class="fal text-white fa-lg fa-check"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[option-large] {
  $square-dim: 3rem;
  width: calc(25% - .5rem);

  .side-square-checked {
    width: $square-dim;
    height: $square-dim;
    top: 0;
    left: 0;
  }

  .main-image {
    width: 70%;

    .ki {
      font-size: 4.3rem;

      @include media-breakpoint-down(lg) {
        font-size: 3.3rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    width: calc(50% - .5rem);
  }
}
</style>