<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="w-100" tile-ask>
    <div class="card border-0">
      <div v-if="category" class="card-header pt-3 pb-0 bg-white text-center border-0 d-flex flex-column align-items-center">
        <i class="ki text-primary pb-2" :class="`ki-${categoryIcon}`"></i>
        <p>{{ category }}</p>
      </div>
      <div class="card-body pt-4 d-flex flex-column align-items-center text-center">
        <div class="text-container d-flex align-items-center"><h6 class="text-question h5">{{ content.title }}</h6></div>
      </div>
      <div class="card-footer border-0 bg-white py-3 text-center">
        <a :href="content.slug" class="small text-primary text-uppercase">{{ ft("globals.discover_more") }}<i class="pl-2 fal fa-arrow-right"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash-es';

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl
  },
  data() {
    return {};
  },
  computed: {
    tileDescription() {
      return this.content.short_description || this.content.description
    },
    category() {
      const decorator = Fandom.getDecorator(this.content, "label");
      const label = get(decorator, "title", false);
      return get(this.content, "category", label);
    },
    categoryIcon() {
      const decorator = Fandom.getDecorator(this.content, "label");
      return get(decorator, "icon", "");
    }
  }
};
</script>

<style lang="scss">
[tile-ask] {
  .card {
    height: 100%;

    &-header {
      i {
        font-size: 2.5rem;
      }
    }

    &-body {
      .text-container {
        height: 100px;

        .text-question {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
