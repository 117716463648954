<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 5,
      carousel: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (Fandom.exists(this.content.children)) {
        this.carousel = new Flickity(this.$refs["flickity-carousel"], {
          draggable: ">1",
          prevNextButtons: false,
          pageDots: (this.content.children.length > 1),
          cellAlign: "left",
          wrapAround: true
        });
      }
    });
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :style="theme" grid-carousel-component>
    <div class="themed-item" :class="paddingY">
      <div class="container">
        <div class="row">
          <div class="col-12 px-0">
            <div class="flickity-carousel mx-md-n2" ref="flickity-carousel">
              <div class="flickity-carousel-item pl-4 pr-md-0 px-md-2" v-for="(child, index) in content.children" :key="`carousel-slide-${index}`" :child_name="child">
                <div class="w-100">
                  <component :is="getContent(child).layout" :content="getContent(child)"></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-4" v-if="content.button">
          <a class="btn" :class="getPrimaryButtonClass(content.button_class)" :href="content.button.url" :target="getTarget(content.button)">
            {{content.button.title}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[grid-carousel-component] {
  position: relative;

  .flickity-page-dots {
    bottom: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;

    .dot {
      width: 1rem;
      height: 1rem;

      &.is-selected {
        background-color: $primary;
      }
    }
  }

  .flickity-carousel-item {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    .flickity-carousel-item {
      width: calc(100% / 3);
    }
  }

  @include media-breakpoint-down(md) {
    .flickity-carousel-item {
      width: 85%;
    }
  }
}
</style>
