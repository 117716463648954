<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      userInfo: {},
      loading: false,
      errors: [],
      success: false
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.success = false;
      this.errors = [];

      Fandom.ajax({
        method: "POST",
        data: { user: JSON.stringify(this.userInfo) },
        url: "/api/v5/update_user_password",
        success: (data) => {
          this.success = !!data.success;
          if (!data.success) {
            this.errors = data.errors;
          } else {
            this.$emit('done');
          }
          this.loading = false;
        }
      });
    }
  },
  computed: {
    submitTextFormatted() {
      return this.loading ? this.ft("globals.form.loading") : this.ft("globals.form.send_password");
    }
  }
};
</script>

<template>
  <form user-password-editing-form-component v-on:submit.prevent="submit($event)">          
    <div class="form-group">
      <label>{{ft("globals.form.current_password")}} *</label>
      <input v-model="userInfo.current_password" class="form-control" type="password" required>
    </div>
    <div class="form-group">
      <label>{{ft("globals.form.new_password")}} *</label>
      <input v-model="userInfo.password" class="form-control" type="password" required>
    </div>
    <div class="form-group password-edit-instructions" v-if="content.password_edit_instructions">
      <div v-html="content.password_edit_instructions"></div>
    </div>

    <small class="form-text text-muted mb-2">{{ft("globals.form.required_fields")}}</small>

    <div class="row">
      <div class="col-12 col-md-6 mx-auto">
        <button type="submit" class="btn btn-primary w-100 mb-3 text-uppercase" :class="{'disabled': loading}" :disabled="loading">
          {{submitTextFormatted}}
        </button>
      </div>
    </div>

    <form-feedback :success-message="ft('globals.user_edit_password.success')" :success="success" :errors="errors"></form-feedback> 
  </form>
</template>

<style lang="scss" scoped>
[user-password-editing-form-component] {
  .form-group {
    margin-bottom: 2rem;
  }
}
</style>
