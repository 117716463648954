<script>
import { map, merge } from "lodash";
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      moked_content_name: null,
      loading: true,
      searchText: "",
      activeSearch: ""
    };
  },
  mounted() {
    this.searchText = Fandom.getUrlParameter("q");
    Vue.nextTick(() => {
      this.applyFilters();
    });
  },
  methods: {     
    applyFilters() {
      this.activeSearch = this.searchText;
      this.getSearchableContents(false);
    },
    getSearchableContents(append) {
      Fandom.getSearchableContents(
          (data) => {
            this.moked_content_name = data.main_content_name;
            const search_content = {};
            Vue.set(this.content.search_content, "children", []);
            Vue.set(this.content.search_content, "has_more_children", false);
            merge(search_content, this.content.search_content, globalState.pageModel.name_to_content[this.moked_content_name]);
            Vue.set(this.content, "search_content", search_content);
            this.loading = false;
          }, this.getContentsParams(append), append
        );
    },
    appendSearchableContents() {
      this.getSearchableContents(true);
    },
    getContentsParams(append) {
      let contentFiltering = [];
      let exclude_content_ids = [];

      if (!!this.content.filter_contents && this.content.filter_contents.length > 0) {
        contentFiltering = contentFiltering.concat([this.content.filter_contents]);
      }

      if (append) {
        exclude_content_ids = map(globalState.pageModel.name_to_content[this.moked_content_name].children, c => this.getContent(c).id);
      }

      const params = {
        main_content_name: this.moked_content_name,
        layout: this.content.search_content.children_layout,
        include_parent_names: true,
        include_children_count: true,
        content_filtering: contentFiltering,
        exclude_content_ids: exclude_content_ids
      };
      if (Fandom.exists(this.searchText)) { 
        params.search_text = Fandom.searchTextToBackendFormat(this.searchText);
      }
      return params; 
    },
    redirect(slug) {
      window.location.href = slug;
    }
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" stripe-search-component>
    <div class="heading">
      <div class="container">
        <div class="row">
          <div class="col-md-10 my-5">
            <h3 class="search-heading font-weight-normal">{{ft("globals.you_searched_for")}}</h3>
            <input type="text" class="custom-input" v-model="searchText" :placeholder="ft('globals.you_searched_for_placeholder')" v-on:keyup.enter="applyFilters()">
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="results-count text-muted">
        {{(content.search_content ? content.search_content.children_count : 0)}} {{ft("globals.advanced_search.results")}}
      </div>
    </div>
    <grid-fixed v-if="!loading" class="w-100 pb-5" :content="content.search_content" :opt-container-content="containerContent" :opt-append-contents="appendSearchableContents"></grid-fixed>
    <div class="py-5 w-100 text-center" v-else>
      <i class="fad fa-spinner fa-spin text-primary fa-pulse fa-3x"></i>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-search-component] {
  .heading {
    background-color: $gray-200;
  }

  .custom-input {
    height: 3.75rem;
    padding: 0;
    font-size: 2.25rem;
    text-transform: uppercase;
    color: $dark;
    background-color: transparent;
    border: 0;
    outline-width: 0px;

    &::placeholder {
      color: $gray-400;
    }
  }
}
</style>