<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Gigya } from 'src/modules/gigya_module';

export default {
  mixins: [fandomBasicMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    side: {
      type: String,
      default: "left"
    },
    menuItems: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      activeItem: null,
      menu: [...this.menuItems]
    };
  },
  mounted() {
    if (this.content.top_links) {
      this.menu = [...this.content.top_links, ...this.menu];
    }
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    getTarget: Fandom.getTarget,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getItemUrl(item) {
      return item.url.url == "#" ? "javascript:void(0)" : item.url.url;
    },
    itemClick(item) {
      if (item.menu) {
        this.activeItem = item;
      }
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    beforeEnter() {
      this.showSidebarLayer = true;
      disableBodyScroll(this.$el);
    },
    beforeLeave() {
      clearAllBodyScrollLocks();
      this.showSidebarLayer = false;
      this.activeItem = null;
    },
    logout() {
      const _gigya = new Gigya("IT");
      _gigya.logout();
    }
  },
  watch: {
    menuItems(newVal) {
      if (newVal) {
        const topLinks = this.content.top_links.length > 0 ? this.content.top_links : []
        this.menu = [
          ...topLinks,
          ...newVal
        ];
      }
    }
  }
};
</script>

<template>
  <div>
    <transition 
        :enter-active-class="`animated faster slideIn${capitalize(side)}`"
        :leave-active-class="`animated faster slideOut${capitalize(side)}`"
        @before-enter="beforeEnter"
        @before-leave="beforeLeave"
      >
        <div v-if="show" class="sidebar position-fixed mh-100vh" sidebar-component :class="`sidebar--${side}`">
          <div class="position-absolute w-100 bg-white d-flex flex-column">
            <div class="pr-2 pl-3">
              <div class="sidebar__header px-3 py-4 w-100 position-relative border-gray-primary">
                <div class="close-icon my-1 h-100" @click="$emit('sidebarClose', true)">
                  <div class="text-uppercase pr-3">{{ ft('globals.advanced_search.close') }}</div>
                  <i class="ki ki-close"></i>
                </div>
              </div>
            </div>
            <slot name="sidebar-header"></slot>
            <div 
              v-for="(item, index) in menu"
              :class="`bg-${item.background_color ? item.background_color : 'white'}`"
              :key="`menu-item-${index}`"
              class="pr-2 pl-3"
            >
              <div class="px-3 py-4 border-bottom w-100" :class="`border-${item.background_color ? item.background_color : 'gray-primary'}`">
                <div class="mx-3 my-1 d-flex justify-content-between align-items-center">
                  <a 
                    :class="`text-${item.text_color ? item.text_color : 'dark'}`"
                    class="w-100 text-uppercase"
                    :href="getItemUrl(item)"
                    :target="getTarget(item.url)"
                    :rel="getRelNoFollow(item.url)"
                  >
                    <i v-if="item.icon" :class="item.icon" class="fa-lg pr-3"></i>
                    {{ item.url.title }}
                  </a>
                  <i v-if="item.menu" @click="itemClick(item)" class="fal fa-chevron-right fa-lg"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="position-absolute sidebar__footer w-100">
            <div class="bg-white">
              <div class="px-3 py-4 w-100">
                <div class="mx-3 my-1 d-flex justify-content-between align-items-center">
                  <a class="w-100 text-uppercase text-dark" :href="content.support.url">
                    <i class="ki ki-support fa-lg"></i>
                    {{ content.support.title }}
                  </a>
                  <div class="socials d-flex align-items-center" v-if="content.social_icons">
                    <div v-for="(social, index) in content.social_icons" class="ml-3" :key="index">
                      <a 
                        v-if="social.url" 
                        :href="social.url.url" 
                        :target="getTarget(social.url)" 
                        class="link-dark"
                      >
                        <i v-if="social.icon" :class="social.icon" class="fab fa-lg"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition 
            :enter-active-class="`animated faster slideIn${capitalize(side)}`"
            :leave-active-class="`animated faster slideOut${capitalize(side)}`"
          >
            <sidebar-item 
              v-if="!!activeItem" 
              :item="activeItem" 
              @sidebar-item-close="activeItem = null"
            ></sidebar-item>
          </transition>
        </div>
    </transition>
  </div>
</template>

<style lang="scss">
[sidebar-component] {
  z-index: $zindex-menu-item + 2;
  top: 0;
  width: 80vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &.sidebar {
    &--right {
      right: 0;

      .sidebar__header {
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }
    }

    &__header {
      height: $navbar-height;
    }
  }

  .sidebar__footer {
    bottom: 0;
  }

  & > div:first-child {
    min-height: 100%;
  }

  .close-icon {
    z-index: 3;
    font-size: 1.5rem;
    opacity: .85;
    line-height: .85rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    div {
      font-size: .75rem;
    }
  }

  a {
    text-decoration: none !important;
  }
}
</style>
