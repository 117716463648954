<script>
import { get } from "lodash";
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return { };
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    getTarget: Fandom.getTarget,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    rightBoxItems(menuItem) {
      let  items = [];
      for (let i = 0; i < menuItem.menu.length/2; i++) {
        items[i] = [];
      }
      menuItem.menu.forEach((section, index) => {
        const pos = Math.floor(index/2);
        items[pos].push(section);
      });
      return items;
    },
    linkUrl(item) {
      const url = get(item, ["url", "url"], false);
      return !url || url == "#" ? "javascript:void(0)" : url;
    },
    isItemActive(item) {
      const re = new RegExp(`(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    getSubItemColClass(item) {
      const n = item.menu.length;
      return `col-${n < 3 ? 12 : n < 4 ? 6 : 4}`;
    },
    getItemColClass(item) {
      const n = item.menu.length;
      return [`col-${n < 3 ? 6 : 12}`];
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.sm || globalState.viewport.xs;
    },
    menu() {
      return this.isMobile ? (this.content.mobile_highlights || []) : this.content.menu;
    }
  },
};
</script>

<template>
  <div class="w-100 d-flex border-bottom border-gray-primary" navbar-component>
    <div class="hamburger d-md-none px-md-4 mx-md-3 border-right border-lg-right-none border-gray-primary" @click="$emit('toggle-sidebar')">
      <i class="fal fa-bars fa-lg"></i>
    </div>
    <div class="d-flex justify-content-center align-items-center w-100">
      <div 
        class="position-relative menu-item-container px-md-3 px-2"
        v-for="(item, index) in menu"
        :key="`menu-${index}`"
      >
        <div 
          class="menu-item position-relative"
          :class="[`bg-hover-${item.color ? item.color : 'primary'}`, {'active': isItemActive(item)}]"
        >
          <a 
            :href="linkUrl(item)" 
            :target="getTarget(item.url)"
            :rel="getRelNoFollow(item.url)"
            class="link-dark px-md-4 px-2"
            :id="`navbar-item-${index}`"
          >{{item.url.title}}</a>
          <div class="sub-menu position-absolute d-none mx-0" :style="{'max-width': item.max_width}" v-if="item.menu">
            <div class="row mx-0">
              <div :class="getItemColClass(item)[0]" class="p-0">
                <div class="m-3 p-3 row position-relative">
                  <div 
                    v-for="(couple, idx) in rightBoxItems(item)" 
                    :key="`sub-item-${idx}`"
                    :class="getSubItemColClass(item)" 
                    class="col-4 d-flex flex-column"
                  >
                    <div v-for="section in couple" :key="section.title" class="d-flex flex-column mb-3">
                      <span class="section small" :class="`text-${item.color ? item.color : 'primary'}`">
                        {{section.title}}
                      </span>
                      <div v-for="(subItem, subIdx) in section.menu" :key="`subItem-${subIdx}`">
                        <a
                          :href="subItem.url.url"
                          :target="getTarget(subItem.url)"
                          class="sub-item"
                          :rel="getRelNoFollow(subItem.url)"
                        >{{subItem.url.title}}</a>
                      </div>
                    </div>
                    <div v-if="couple.length == (index) && item.corner_url" class="d-flex flex-column mb-3">
                      <span class="section font-sm corner-url" :class="`text-${item.color ? item.color : 'primary'}`">
                        <a 
                          :class="`text-${item.color ? item.color : 'primary'}`"
                          :href="item.corner_url.url"
                          :target="getTarget(item.corner_url)"
                          :rel="getRelNoFollow(item.corner_url)"
                        >
                          {{item.corner_url.title}}
                        </a>
                        <i class="far fa-chevron-right ml-1"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[navbar-component] { 
  height: $navbar-height;

  .hamburger {
    font-size: 1.25rem;
    line-height: 1rem;
    z-index: 1;
    height: 100%;
    min-width: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    height: $navbar-height-mobile;

    .menu-item-container {
      flex: 1;
    }
  }

  .menu-item-container {
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        height: 1rem;
        width: 1px;
        top: 8px;
        right: 0;
        background-color: rgba($black, .75);
      }
    }
  }

  .menu-item {
    z-index: $zindex-menu-item;
    transition: all $animation-time-fast;
    height: $top-level-item-height;
    border-color: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

    & > a {
      text-decoration: none;
    }

    &:hover,
    &.active {
      & > a {
        color: $white !important;
      }
    }

    &:hover .sub-menu {
      display: block !important;
    }

    & > a {
      height: 1.2rem;
    }
  }

  .sub-menu {
    top: $top-level-item-height;
    background-color: $white;
    cursor: default;
    color: $dark;
    z-index: 2;
    width: 100vw;
    max-width: 40rem;
    left: -1.5rem;

    a.sub-item {
      color: $dark;
      text-transform: capitalize;
      font-size: .75rem;
    }
  }

  .corner-url {
    
    a {
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 0.875rem;
    }

    .far.fa-chevron-right {
      font-size: 0.875rem; /* 14/16 */
    }

  }
}
</style>

