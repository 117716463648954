<script>
import { fandomTranslate } from "src/modules/common_module";

export default {
  data() {
    return {
      courseId: {}
    };
  },
  methods: {
    ft: fandomTranslate,
    confirm() {
      Fandom.emit("course-unsubscription-confimed", this.courseId);
    },
    cancel() {
      $(this.$refs["modal-course-unsubscribe"]).modal("hide");
    }
  },
  mounted() {
    Fandom.onEvent("modal-course-unsubscribe-required", (courseId) => {
      this.courseId = courseId;
      Vue.nextTick(() => {
        $(this.$refs["modal-course-unsubscribe"]).modal({backdrop: 'static', keyboard: false});
      });
    });
  }
}
</script>

<template>
  <div ref="modal-course-unsubscribe" class="modal fade" tabindex="-1" role="dialog" modal-course-unsubscribe-component>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body p-5 text-center">
          <h5>{{ft("globals.courses.confirm_unsubscribe")}}</h5>
          <div class="d-flex justify-content-between">
            <button class="btn btn-primary text-uppercase px-4 py-2 mr-3" @click="confirm">{{ft("globals.courses.confirm_unsubscribe_ok")}}</button>
            <button class="btn btn-primary text-uppercase px-4 py-2" @click="cancel">{{ft("globals.courses.confirm_unsubscribe_cancel")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>