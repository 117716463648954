<script>

import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { favouriteInteractionMixin} from 'src/modules/favourite_interaction_module.js';
import { map, merge } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin, favouriteInteractionMixin, fandomExtendedMixin],
  data: function() {
    return {
      modalListInfo: { listId: null, listName: null, errors: [] },
      focusedListInfo: { listId: null, listName: null },
      loadingLists: true,
      loadingFavouriteContents: true
    };
  },
  mounted: function() {
    this.getFavouriteLists((data) => {
      this.loadingLists = false;
      this.setDefaultList();
      this.getFavouriteContents(this.focusedListInfo.listId);
    });

    $('#favourite-stripe-add-list, #favourite-stripe-edit-list, #favourite-stripe-remove-list').on(
      'hidden.bs.modal', (e) => { 
        this.modalListInfo = { listId: null, listName: null, errors: [] };
      });
  },
  methods: {
    setDefaultList() {
      for (let listId in globalState.pageModel.favouriteLists) {
        if (!globalState.pageModel.favouriteLists[listId]) {
          this.focusedListInfo.listId = listId;
          this.focusedListInfo.listName = null;
          break;
        }
      }
    },
    openAddFavouriteModal() {
      $("#favourite-stripe-add-list").modal('show');
    },
    openEditFavouriteModal(listId, listName) {
      this.focusList(listId, listName);
      this.modalListInfo.listId = this.focusedListInfo.listId;
      this.modalListInfo.listName = this.focusedListInfo.listName;
      $("#favourite-stripe-edit-list").modal('show');
    },
    openRemoveFavouriteModal(listId, listName) {
      this.focusList(listId, listName);
      this.modalListInfo.listId = this.focusedListInfo.listId;
      this.modalListInfo.listName = this.focusedListInfo.listName;
      $("#favourite-stripe-remove-list").modal('show');
    },
    addFavouriteList(listName) {
      let successCallback = (data) => {
        if (data.success) {
          Vue.set(globalState.pageModel.favouriteLists, data.favourite_list.id, data.favourite_list.name);
          $("#favourite-stripe-add-list").modal('hide');
        } else {
          this.modalListInfo.errors = data.errors;
        }
      }
      this.createFavouriteList(listName, successCallback);
    },
    editFavouriteListName() {
      let successCallback = (data) => {
        if (data.success) {
          $("#favourite-stripe-edit-list").modal('hide');
          Vue.set(globalState.pageModel.favouriteLists, data.favourite_list.id, data.favourite_list.name);
          this.focusedListInfo.listName = data.favourite_list.name;
        } else {
          this.modalListInfo.errors = data.errors;
        }
      };
      if (this.modalListInfo.listName != this.focusedListInfo.listName) {
        this.editFavouritList(this.modalListInfo.listId, this.modalListInfo.listName, successCallback);
      }
    },
    removeFavouriteList() {
      let successCallback = (data) => {
        this.reset();
        this.getFavouriteContents(this.focusedListInfo.listId);
        $("#favourite-stripe-remove-list").modal('hide');

      };
      this.deleteFavouriteList(this.modalListInfo.listId, successCallback);
    },
    getFavouriteContents(listId, append = false) {
      this.loadingFavouriteContents = true;
      this.getContentsFromFavouriteList(listId, 
          (this.content.favourite_content.children ? map(this.content.favourite_content.children, c => this.getContent(c).id) : []), 
          (data) => {
            Fandom.getContentsCallback(data.main_content_name, data, () => {
              const favourite_content = {};
              merge(favourite_content, this.content.favourite_content, globalState.pageModel.name_to_content[data.main_content_name]);
              Vue.set(this.content, "favourite_content", favourite_content);
              this.loadingFavouriteContents = false;
            }, append);        
          }, 
          this.content.favourite_content.children_layout
        );
    },
    appendFavouriteContents() {
      this.getFavouriteContents(this.focusedListInfo.listId, true);
    },
    focusList(listId, listName) {
      if (listId != this.focusedListInfo.listId) {
        this.reset();
        this.focusedListInfo.listName = listName;
        this.focusedListInfo.listId = listId;
        this.getFavouriteContents(listId);
      }
    },
    reset() {
      this.setDefaultList()
      this.content.favourite_content.children = [];
      this.content.favourite_content.has_more_children = false;
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" :style="theme" :id="anchorId" stripe-favourite-component>
    <div class="themed-item">
      <div class="container py-5">
        <div class="row">
          <div class="col-12 col-lg-auto">
            <div class="sidebar">
              <div class="sidebar_heading mb-5">
                <div class="mb-3" v-html="content.description"></div>
                <button class="btn btn-primary btn-block" @click="openAddFavouriteModal('favourite-stripe-add-list')" >{{ft("favourite.create_list_title")}}</button> 
              </div>
              <div class="sidebar_body">
                <p class="text-uppercase">{{ft("favourite.list_lists")}}</p>
                <ul v-if="globalState.pageModel.favouriteLists && !loadingLists" class="my-2 py-3 border-top border-bottom list-unstyled">
                  <li v-for="(listName, listId) in globalState.pageModel.favouriteLists" :key="listId" class="p-3 text-uppercase d-flex justify-content-between cursor-pointer" @click="focusList(listId, listName)" :class="{'active': focusedListInfo.listName == listName}">
                    <div>- {{listName || ft("favourite.default_list")}}</div>
                    <div v-if="listName">
                      <i class="fal fa-pen mr-2" @click.prevent="openEditFavouriteModal(listId, listName)"></i>
                      <i class="fal fa-trash" @click.prevent="openRemoveFavouriteModal(listId, listName)"></i>
                    </div>
                  </li>
                </ul>
                <div class="py-5 w-100 text-center" v-else>
                  <i class="fad fa-spinner fa-spin fa-3x text-primary fa-pulse"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg">
            <component class="w-100"
                       v-if="content.favourite_content.children && !!content.favourite_content.children.length"
                       :is="content.favourite_content.layout" 
                       :content="content.favourite_content" :opt-container-content="containerContent" 
                       :opt-append-contents="appendFavouriteContents">
            </component>
            <div class="w-100 text-center" v-else-if="!loadingFavouriteContents">
              <span class="h5">{{ft("favourite.missing_contents")}}</span>
            </div>
            <div class="py-5 w-100 text-center" v-else-if="loadingFavouriteContents">
              <i class="fad fa-spinner fa-spin fa-3x text-primary fa-pulse"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="favourite-stripe-add-list" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ft("favourite.create_list_title")}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>{{ft("favourite.create_list_description")}}</label>
              <input class="form-control" type="text" v-model="modalListInfo.listName">
            </div>
            <div v-if="!!modalListInfo.errors.length">
              <p>{{ft("favourite.errors.heading")}}</p>
              <ul>
                <li v-for="error in modalListInfo.errors">{{error}}</li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" :disabled="!modalListInfo.listName" @click="addFavouriteList(modalListInfo.listName)">{{ft("favourite.create_list")}}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ft("globals.ugc_recipe.delete_undo")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- edit modal -->
    <div id="favourite-stripe-edit-list" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ft("favourite.edit_list_title")}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>{{ft("favourite.edit_list_description")}}</label>
              <input class="form-control" type="text" v-model="modalListInfo.listName">
            </div>
            <div v-if="!!modalListInfo.errors.length">
              <p>{{ft("favourite.errors.heading")}}</p>
              <ul>
                <li v-for="error in modalListInfo.errors">{{error}}</li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="editFavouriteListName()" >{{ft("favourite.edit_list")}}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ft("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- delete modal -->
    <div id="favourite-stripe-remove-list" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{modalListInfo.listName}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ft("favourite.remove_list_description")}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="removeFavouriteList()">{{ft("favourite.remove_list")}}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ft("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- -------- end modals -------- -->

  </div>
</template>

<style lang="scss">
[stripe-favourite-component] {
  .sidebar_body ::v-deep {
    .container { width: 100%; }
  }

  .sidebar {

    @include media-breakpoint-up(lg) {
      width: 22rem;
    }

    ul li:hover, ul li.active {
      background-color: $light;
      color: $primary;
    }
  }
}
</style>
