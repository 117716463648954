<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function() {
    return {
      autocompleteRef: null,
      autocompleteSelectedContent: null,
      autocompleteContents: [],
      paddingYDefault: 5
    };
  },
  mounted() {
    this.autocompleteRef = this.$refs["v-autocomplete-ref"];
    // keyEnter monkeypatch
    const keyEnter = this.autocompleteRef.keyEnter;
    this.autocompleteRef.keyEnter = (e) => {
      keyEnter();
      if (!this.autocompleteSelectedContent && this.content.search_content_url) {
        this.submit();
      }
    }
  },
  methods: {
    submit() {
      const searchText = this.autocompleteRef.searchText;
      window.location.href = `${this.content.search_content_url.url}?q=${encodeURI(searchText)}`;
    },
    getLabel(item) {
      return item.title;
    },
    filtersToGetContentsParams(searchText) {
      const params = {
        layout_extra_field_name: "tile_layout",
        layout: "tile",
        include_rich_info: false,
        search_text: Fandom.searchTextToBackendFormat(searchText),
        content_filtering: [this.content.content_filtering]
      };
      return params;
    },
    handleUpdateItems(searchText) {
      this.autocompleteContents = [];
    
      const successBlock = (data) => {
        const mainContent = data.name_to_content[data.main_content_name];
        if (Fandom.exists(mainContent.children)) {
          this.autocompleteContents = mainContent.children.map(name => data.name_to_content[name]);
        }
      }

      const params = this.filtersToGetContentsParams(searchText);
      Fandom.getAutocompleteContents(params, successBlock);
    },
    handleItemSelected(content) {
      if (Fandom.exists(content.url)) {
        window.location.href = content.url.url;
      } else if (Fandom.exists(content.slug)) {
        window.location.href = Fandom.applyContextToUrl(content.slug);
      }
    },
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType, paddingY]" :style="theme" v-if="isContentVisibleToUser()" class="themed-item" stripe-search-autocomplete-component>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="d-flex position-relative">
            <v-autocomplete
              ref="v-autocomplete-ref"
              class="form-control pl-3 pl-md-2 p-2 d-flex align-items-center" 
              :items="autocompleteContents"
              v-model="autocompleteSelectedContent"
              component-item="stripe-search-autocomplete-item"
              :get-label="getLabel"
              @item-selected="handleItemSelected"
              @update-items="handleUpdateItems"
              :input-attrs="{ placeholder : ft('globals.you_searched_for_placeholder') }"
              :auto-select-one-item="false"
            ></v-autocomplete>
            <button 
              class="search-btn position-absolute btn btn-primary p-2 pr-3 h-100 bg-white text-dark border-0 d-flex align-items-center"
              v-if="content.search_content_url"
              @click="submit()"
            >
              <i class="fal fa-search fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-search-autocomplete-component] {

  .search-btn {
    border-radius: 0;
    right: 0;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .v-autocomplete {
    border: none !important;

    &-input-group {
      width: 100%;
      background-color: $white;
      padding-top: 1px;
      padding-bottom: 1px;

      .v-autocomplete-input {
        border: none;
        box-shadow: none;
        width: 100%;
        outline: none !important;
      }
    }

    &-list {
      top: 3rem;
      left: 0;
      right: 0;
      z-index: $zindex-dropdown;

      &-item {
        background-color: $white;
      }
    }
  }
}
</style>
