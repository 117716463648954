<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      carousel: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (Fandom.exists(this.content.children)) {
        this.carousel = new Flickity(this.$refs["flickity-carousel"], {
          draggable: ">1",
          prevNextButtons: false,
          pageDots: (this.content.children.length > 1),
          cellAlign: "left",
          wrapAround: true
        });
      }
    });
  },
  computed: {
    componentClasses() {
      let classes = [ this.contentType ];
      if (this.content.theme) {
        classes.push(`${this.content.theme}-theme`);
      }
      return classes;
    },
    contentStyle() {
      return {
        "background-color": this.content.background_color,
        "color": this.content.color
      };
    },
    pagerClass() {
      return `per-page-${get(this, "content.per_page", 3)}`;
    }
  }
};
</script>

<template>
  <!-- margin management -->
  <div v-easyadmin="easyadminId" :class="[componentClasses, paddingY]" :style="[theme, contentStyle]" stripe-carousel-component>
    <div class="container">
      <div class="row">
        <div class="col-12 px-0">
          <div class="flickity-carousel" ref="flickity-carousel" :class="pagerClass">
            <div class="flickity-carousel-item" v-for="(child, index) in content.children" :key="`carousel-slide-${index}`" :child_name="child">
              <div class="w-100">
                <component :is="getContent(child).layout" :content="getContent(child)"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- margin management -->
    <div class="container">
      <div class="row justify-content-center my-4" v-if="content.button">
        <a class="btn" :class="getPrimaryButtonClass(content.button_class)" :href="content.button.url" :target="getTarget(content.button)">
          {{content.button.title}}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-component] {
  position: relative;

  .flickity-page-dots {
    bottom: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;

    .dot {
      width: 1rem;
      height: 1rem;

      &.is-selected {
        background-color: $primary;
      }
    }
  }

  .flickity-carousel-item {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    @for $i from 1 through 6 {
      .per-page-#{$i} {
        .flickity-carousel-item {
          width: 100% / $i;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .flickity-carousel-item {
      width: 85%;
    }
  }
}
</style>
