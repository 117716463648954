<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import tileMixin from '../../modules/kenwood_austria_tile_mixin.js';

export default {
  mixins: [fandomBasicMixin, interactionMixin, tileMixin],
  data() {
    return {};
  },
  computed: {
    favourite() {
      return (this.content.interactions && this.content.interactions["favourite"]) ? this.content.interactions["favourite"] : false;
    },
    like() {
      return (this.content.interactions && this.content.interactions["like"]) ? this.content.interactions["like"] : false;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="w-100" tile-recipe>

    <div class="card border-0 bg-transparent">
      <div class="card-header d-flex justify-content-between bg-transparent py-2 px-0">
        <div v-if="owner" class="card-header__owner d-flex align-items-center">
          <component :is="owner.url ? 'a' : 'div'" :href="owner.url ? owner.url.url : null" :target="getTarget(owner.url)" class="user-info-content__avatar-container">
            <div class="owner-avatar bg-cover-center d-flex justify-content-center align-items-center" :style="avatarStyle">
              <span v-if="!ownerAvatarUrl" class="text-white">{{ownerInitials}}</span>
            </div>
          </component>
          <div class="owner-name pl-2">{{owner.username}}</div>
        </div>
        <div class="card-header__interactions d-flex justify-content-end">
          <div class="interactions-content d-flex align-items-center">
            <div v-if="like" class="interactions-like d-flex align-items-center">
              <span>{{getLikeCounter(containerContent, like.interaction, "")}}</span>
              <i class="px-2 fa-heart fa-lg" :class="[isLikeDone(containerContent, like.interaction) ? 'fas text-primary' : 'fal']" @click="updateLikeInteraction(containerContent, like, like.interaction)"></i>
            </div>
            <div v-if="favourite" class="interactions-favourite d-flex">
              <i class="fa-bookmark fa-lg px-1" :class="[isFavourite(containerContent, favourite.interaction) ? 'fas text-primary' : 'fal']" @click="mayOpenFavouriteModal(containerContent, favourite, favourite.interaction)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0 overflow-hidden">
        <a class="position-relative" :href="content.slug">
          <div v-if="category" class="card-body__category" :style="categoryTheme">
            <span class="d-block px-4 py-2 py-md-1 text-xs">{{ category }}</span>
          </div>
          <div class="ar-16by9 h-100 bg-cover-center w-100 animate-zoom" :style="{'background-image': getTileThumbnailUrl('thumbnail')}">
          </div>
          <div class="icon-play-container d-flex align-items-center justify-content-center" v-if="content.play">
            <i class="fal fa-play ml-1"></i>
          </div>
        </a>
      </div>
      <div class="card-footer bg-transparent px-0 pt-3 pb-0 pt-md-2 pb-md-2">
        <p class="h5 mb-0 mb-md-3">{{content.title}}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[tile-recipe] {
  $title-height: 16%;

  .icon-play-container {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border: 1px solid $light;
    height: 45px;
    width: 45px;
    border-radius: 100%;;
    i { color: $light !important; }
  }

  .card {
    &-header {
      border-top: 1px solid $gray-600;
      border-radius: 0;
      min-height: 60px;

      &__interactions {
        flex: 1;
      }
    }

    .interactions {
      &-like, &-favourite {
        i {
          cursor: pointer;

          &:hover {
            color: $primary;
          }
        }
      }

      &-like {
        span {
          font-size: .75rem;
        }
      }
    }

    .owner {
      &-name {
        font-weight: 600;
        font-size: .75rem;
        max-height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-avatar {
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        background-color: $primary;
        border: 1px solid $gray-600;
      }
    }

    &-body {
      &__category {
        position: absolute;
        bottom: 0;
        background: var(--category-background);
        color: var(--category-color);
        text-transform: uppercase;
        z-index: 1;

        ::before, ::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          right: -10px;
        }

        ::before {
          border-width: 17px 10px 0 0;
          border-color: var(--category-background) transparent transparent transparent;
          top: 0;
        }

        ::after {
          border-width: 17px 0 0 10px;
          border-color: transparent transparent transparent var(--category-background);
          bottom: 0;
        }
      }
    }
  }

  .play-icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: $title-height / 2;
  }  
}
</style>
