<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      carousel: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (!!this.content.items && this.content.items.length > 0) {
        this.carousel = new Flickity(this.$refs["flickity-carousel-products"], {
          draggable: ">1",
          pageDots: false,
          cellAlign: "center",
          prevNextButtons: false,
          wrapAround: true,
        });

        window.setTimeout(() => this.carousel.resize(), 200)
      }
    });
  },
}
</script>

<template>
  <div v-easyadmin="easyadminId" :style="theme" :class="[contentType, paddingY]" :id="easyadminId" list-carousel-products-component>
    <div class="container">
      <div class="row">
        <div class="col-12 px-0 px-md-3">
          <div class="position-absolute-center d-flex justify-content-center align-items-start" v-if="carousel">
            <div class="controls-container position-relative">
              <div class="position-absolute-center d-flex justify-content-between align-items-center mx-n5">
                <i class="fal fa-2x fa-chevron-left cursor-pointer" @click="carousel.previous()"></i>
                <i class="fal fa-2x fa-chevron-right cursor-pointer" @click="carousel.next()"></i>
              </div>
            </div>
          </div>
          <div class="flickity-carousel-products w-100 text-center" ref="flickity-carousel-products">
            <div
              v-for="(item, index) in content.items"
              class="flickity-carousel-products-item p-xl-4"
              :key="`carousel-products-slide-${item.$id}`"
              :ref="`carousel-products-slide-${index}`"
            >
              <div class="position-relative w-100 ar-1by1 mb-4">
                <div class="position-absolute w-100 h-100 slide-image-container d-flex justify-content-center align-items-center">
                  <div class="bg-contain-center slide-image" :style="{'background-image': getContentResponsiveImageURL('image', item)}"></div>
                </div>
              </div>
              <div class="product-item__title text-uppercase h6" v-if="item.title">{{item.title}}</div>
              <div class="product-item__description mb-4" v-if="item.description" v-html="item.description"></div>
              <a class="btn btn-dark px-4 mb-4" :class="item.button_class" v-if="item.button" :href="item.button.url" :target="getTarget(item.button)">{{item.button.title}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[list-carousel-products-component] {
  background-color: $gray-200;

  .controls-container {
    width: 33.3333%;
    padding-bottom: 33.3333%;

    i {
      z-index: 1;
    }
  }

  .flickity-carousel-products-item {
    width: 33.3333%;
    
    .btn,
    .product-item__title,
    .product-item__description {
      opacity: 1;
      transition: all .25s;
      font-family: $gotham-font;
      font-style: normal;
    }

    .slide-image-container {
      border-radius: 100%;
      transition: all .25s;
      background-color: $white;

      .slide-image {
        width: 50%;
        padding-bottom: 50%;
        transition: all .5s;
        transform: scale(1.2);
        filter: opacity(100%);
      }
    }
  }

  .flickity-carousel-products-item:not(.is-selected) {

    .slide-image-container {
      background-color: transparent;

      .slide-image {
        transform: scale(1);
        filter: opacity(50%);
      }
    }

    .btn,
    .product-item__title,
    .product-item__description {
      opacity: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .controls-container {
      padding-bottom: 60%;
    }
    .controls-container,
    .flickity-carousel-products-item {
      width: 60%;
    }
  }
}
</style>
