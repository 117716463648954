<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      filters: {
        name: null,
        title: null,
        slug: null,
        date: null
      }
    }
  },
  mounted() {
    this.getCourses();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getCourses();
      }
    }
  },
  methods: {
    getDate(dateString) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return (new Date(dateString)).toLocaleDateString('it-IT', options);
    },
    canToggleCourse(courseName) {
      const course = Fandom.getContent(courseName);
      return new Date(course.deactivated_at) >= new Date(); 
    },
    getCourses(appendContents = false) {
      const data = {
        include_content_children: true,
        include_children: true,
        layout: "default",
        max_depth: 1,
        preview: true,
        order_by: "created_at",
        sorting_order: "desc",
        exclude_content_ids: (this.mainTag || {children:[]}).children.map(c => this.getContent(c).id)
      };
      if (this.filters.date) data["filter_by_date"] = this.filters.date;
      if (this.filters.name) data["filter_by_name"] = this.filters.name;
      if (this.filters.slug) data["filter_by_slug"] = this.filters.slug;
      if (this.filters.title) data["filter_by_title"] = this.filters.title;
      Fandom.getContents(
        this.content.main_tag,
        data,
        (response) => {
          console.log(response);
        },
        appendContents
      )
    },
    toggleCourse(courseName, active) {
      Fandom.ajax({
        method: "POST",
        url: `/simple_form/course/${courseName}/toggle`,
        data: { active },
        success: (data) => {
          Vue.set(this.pageModel.name_to_content[courseName], 'activated_at', data.activated_at);
        }
      })
    },
    exportCourseUsers(courseId, submission_export_hash = null) {
      Fandom.ajax({
        method: "POST",
        url: `/simple_form/course/${courseId}/export`,
        data: {
          submission_export_hash: submission_export_hash
        },
        success: (data) => {
          if (!data.is_last_page) {
            this.export(courseId, data.submission_export_hash);
          } else {
            const exportUrl = "/simple_form/course/export_finalization";
            window.location.href = Fandom.applyContextToUrl(`${exportUrl}?submission_export_hash=${data.submission_export_hash}`);
          }
        }
      });
    }
  },
  computed: {
    mainTag() {
      return this.pageModel.name_to_content[this.content.main_tag] || null;
    },
    courses() {
      return (this.mainTag || {children:[]}).children.filter(n => !n.startsWith("course-template"));
    }
  }
};
</script>

<template>
  <div :id="content.name" v-easyadmin="easyadminId" :class="contentType" class="bg-cover-center themed-item" v-if="isContentVisibleToUser()" :style="[theme]" simple-form-component>
    <div class="container">
      <div class="row py-5">
        <div class="col-12">
          <text-section :content="content" :opt-container-content="optContainerContent"/>
          <div class="d-flex justify-content-end w-100 my-3" v-if="content.course_new_page">
            <a class="btn btn-primary" :href="content.course_new_page.url" :target="getTarget(content.course_new_page.url)">
              {{ft('globals.new')}}
            </a>
          </div>
          <div class="row">
            <div class="form-group col-6 col-md-3">
              <label>{{ft('globals.filter_by')}} {{ft('globals.name')}}</label>
              <input v-model="filters.name" class="form-control" type="text"/>
            </div>
            <div class="form-group col-6 col-md-3">
              <label>{{ft('globals.filter_by')}} {{ft('globals.title')}}</label>
              <input v-model="filters.title" class="form-control" type="text">
            </div>
            <div class="form-group col-6 col-md-3">
              <label>{{ft('globals.filter_by')}} {{ft('globals.slug')}}</label>
              <input v-model="filters.slug" class="form-control" type="text">
            </div>
            <div class="form-group col-6 col-md-3">
              <label>{{ft('globals.filter_by')}} {{ft('globals.date')}}</label>
              <input v-model="filters.date" class="form-control" type="date">
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">{{ft('globals.id')}}</th>
                  <th scope="col">{{ft('globals.name')}}</th>
                  <th scope="col">{{ft('globals.title')}}</th>
                  <th scope="col">{{ft('globals.slug')}}</th>
                  <th scope="col">{{ft('globals.date')}}</th>
                  <th scope="col">{{ft('globals.image')}}</th>
                  <th scope="col">{{ft('globals.actions')}}</th>
                </tr>
              </thead>
              <tbody v-if="mainTag">
                <tr v-for="(course, courseIndex) in courses" :key="`course-${courseIndex}`">
                  <td>{{getContent(course).id}}</td>
                  <td>{{getContent(course).name}}</td>
                  <td>{{getContent(course).title}}</td>
                  <td><a :href="getContent(course).slug" target="__blank">{{getContent(course).slug}}</a></td>
                  <td>{{getDate(getContent(course).date)}}</td>
                  <td><img v-if="getContent(course).thumbnail" class="img-fluid" :src="getContent(course).thumbnail.url"/></td>
                  <td>
                    <div class="d-flex flex-wrap">
                      <a v-if="content.course_edit_page" :href="`${content.course_edit_page.url}?ugc_content_name=${course}`" class="btn btn-outline-gray-primary btn-sm m-1">
                        <i class="fas fa-edit mr-1"></i>
                        {{ft('globals.edit')}}
                      </a>
                      <a href="javascript: void(0)" class="btn btn-outline-gray-primary btn-sm m-1" @click.prevent="exportCourseUsers(getContent(course).id)">
                        <i class="fas fa-file-export mr-1"></i>
                        {{ft('globals.export')}}
                      </a>
                      <a v-if="getContent(course).type === 'online' && getContent(course).zoom_link" :href="getContent(course).zoom_link.url" target="__blank" class="btn btn-outline-gray-primary btn-sm m-1">
                        <i class="fas fa-camera-movie mr-1"></i>
                        {{ft('globals.zoom')}}
                      </a>
                      <button @click="toggleCourse(course, !getContent(course).activated_at)" :disabled="!canToggleCourse(course)" class="btn btn-outline-gray-primary btn-sm m-1">
                        <template v-if="getContent(course).activated_at">
                          <i class="fas fa-eye-slash mr-1"></i>
                          {{ft('globals.deactivate')}}
                        </template>
                        <template v-else>
                          <i class="fas fa-eye mr-1"></i>
                          {{ft('globals.activate')}}
                        </template>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100 d-flex justify-content-center py-4" v-if="mainTag && mainTag.has_more_children">
            <button class="btn btn-primary" @click="getCourses(true)">{{ ft('globals.load_more') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.img-fluid {
  max-height: 6rem;
}
</style>