<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

const dateOptions = {
  weekday: "short",
  day: "numeric",
  month: "long"
};

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    capitalize: Fandom.capitalize,
  },
  data() {
    return {};
  },
  methods: {
    eraseCourseSubscription() {
      Fandom.emit("modal-course-unsubscribe-required", this.content.id);
      Fandom.onEvent("course-unsubscription-confimed", (courseId) => {
        Fandom.post({
          url: `/api/v5/course/${courseId}/unsubscribe`,
          success: () => {
            window.location.reload();
          }
        });
      })
    }
  },
  computed: {
    erasableCourse() {
      return get(this.pageModel, ["user", "courses"], []).indexOf(this.content.name) >= 0;
    },
    tileUrl() {
      if (this.content.form_available === false) return "javascript:void(0);";
      return Fandom.isUserRegistered() ? (this.content.url ? this.content.url.url : Fandom.getContentURL(this.content)) : Fandom.applyContextToUrl("/users/sign_in");
    },
    tileTarget() {
      return this.content.url ? Fandom.getTarget(this.content.url) : "_self";
    },
    date() {
      return (new Date(this.content.date)).toLocaleDateString("de-DE", dateOptions);
    },
    addressUrl() {
      return this.content.address.toLowerCase() === "online" ? 'javascript:void(0)' : `https://www.google.com/maps?q=${this.content.address}`;
    },
  }
};
</script>

<template>
  <div class="w-100" v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" tile-recipe tile-course>
    <div class="card border-0">
      <div class="card-header d-flex justify-content-between bg-white py-2 px-0 align-items-center">
        <div v-if="content.date" class="card-header__owner d-flex">
          <div class="text-muted text-uppercase">{{date}}</div>
        </div>
        <div v-if="!erasableCourse" class="text-uppercase text-white px-3 pt-2 pb-1 available" :class="{'active': content.form_available}">
          {{ft(`globals.courses.${!content.form_available ? 'not_' : ''}available`)}}
        </div>
        <div v-else class="text-white available active cursor-pointer px-3 pt-2 pb-1" @click="eraseCourseSubscription">
          <i class="far fa-lg fa-trash"></i>
        </div>
      </div>
      <div class="card-body p-0">
        <a :href="tileUrl" :target="tileTarget">
          <div class="ar-16by9 h-100 bg-cover-center w-100" :style="{'background-image': getTileThumbnailUrl('thumbnail')}"></div>
        </a>
      </div>
      <div class="card-footer bg-white px-0 pt-4">
        <p class="h5">{{content.title}}</p>
      </div>
      <div v-if="content.subtitle">
        <p v-html="content.subtitle"></p>
      </div>
      <div class="text-muted d-flex align-items-center py-3" v-if="content.address">
        <i class="fal fa-2x fa-map-marker-alt mr-2"></i>
        <component :is="addressUrl === 'javascript:void(0)' ? 'span' : 'a'" class="text-muted link-muted deco-none small" :href="addressUrl" target="_blank">{{content.address}}</component>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[tile-course] {
  $gray-not-active: #bbb9b1;
  $purple-active: #E7407A;

  .text-muted {
    color: #7c7c7c !important;
  }

  .card-header {
    min-height: 0 !important;
  }

  .available {
    background-color: $gray-not-active;

    @include media-breakpoint-down(sm) {
      font-size: $small-font-size;
    }

    &.active {
      background-color: $purple-active;
    }
  }
}
</style>