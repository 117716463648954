<script>
import { interactionMixin, getUrlFromPath } from "src/modules/interactions_module.js";
import { fandomTranslate } from "src/modules/common_module";
import { get } from "lodash";

const textToFlex = {
  right: "end",
  left: "start",
  center: "center"
};

export default {
  mixins: [interactionMixin],
  props: {
    content: Object,
    containerContent: Object,
    customShareSlug: {
      type: String,
      default: ""
    },
    // Placed temporary as a props, maybe it is better to place it in the globalState
    isIframe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shareUrl: ""
    };
  },
  methods: {
    providerIcon(provider) {
      const providerClass = Fandom.getProviderIcon(provider);
      const providerWeight = provider === "link" ? "far" : "fab";
      return [providerClass, providerWeight];
    },
    ft: fandomTranslate,
    startShare(provider) {
      // We must copy the value to the clipboard here because if we go too deep in the call stack
      //   we lose the origin by a click event and so we can't copy anything to the clipboard
      if (["link"].includes(provider)) {
        this.copyToClipboard();
      }
      this.updateShareInteraction(this.containerContent, this.content, this.shareInteraction, provider, this.sharePath, this.isIframe);
    },
    isOnlyMobile(provider) {
      return ["whatsapp"].includes(provider);
    },
    copyToClipboard() {
      const textarea = this.$refs["textarea"];
      const selection = window.getSelection();
      const range = document.createRange();
      const editable = textarea.contentEditable;
      const readOnly = textarea.readOnly;

      textarea.contentEditable = true;
      textarea.readOnly = false;

      this.shareUrl = getUrlFromPath(this.sharePath, this.isIframe, this.content.name);
      range.selectNodeContents(textarea);
      selection.removeAllRanges();
      selection.addRange(range);

      textarea.setSelectionRange(0, 999999);
      textarea.contentEditable = editable;
      textarea.readOnly = readOnly;

      const copyStatus = document.execCommand("copy");
      console.log(copyStatus);
      if (copyStatus) {
        $(this.$refs["modal"]).appendTo("body").modal("show");
      }

      selection.removeAllRanges();
    }
  },
  computed: {
    shareInteraction() {
      return get(this, "content.interactions.share.interaction", {});
    },
    shareProviders() {
      return get(this, "content.interactions.share.providers", "").split(",");
    },
    sharePath() {
      return this.customShareSlug !== "" ? this.customShareSlug : false;
    },
    iconsAlignment() {
      const horizontalAlign = textToFlex[get(this, "content.text_align", "left")];
      return `align-items-center justify-content-${horizontalAlign}`;
    },
    canUseWebShare() {
      return "share" in navigator;
    },
  }
};
</script>

<template>
  <div class="d-flex" :class="iconsAlignment">
    <div v-if="canUseWebShare" class="provider-icon text-center" @click="updateShareInteraction(containerContent, content, shareInteraction, 'webShare', false, false, canUseWebShare)">
      <i class="fal fa-share-alt"></i>
    </div>
    <template v-else>
      <span
        class="cursor-pointer share-icon d-inline-flex align-items-center justify-content-center"
        v-for="(provider, index) in shareProviders"
        :key="index"
        :class="{'d-inline-block d-lg-none': isOnlyMobile(provider), 'mr-2 mr-lg-3': index + 1 < shareProviders.length}"
        @click="startShare(provider)"
      >
        <i class="fa-lg" :class="providerIcon(provider)"></i>
      </span>
      <div class="modal fade" ref="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header position-relative border-0">
              <h5 class="h4 w-100 d-inline-block text-center">{{ft("globals.share_with_friends")}}</h5>
              <button type="button" class="close m-0 p-0" data-dismiss="modal" aria-label="Close">
                <i aria-hidden="true" class="ki ki-close text-black"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="input-group">
                <textarea class="w-100 form-control" ref="textarea" v-model="shareUrl"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>

.form-control {
  border-color: $gray-400;
  box-shadow: none;
}

button, textarea, .form-control {
  outline-color: transparent !important;
  outline-style: none !important;
}

.share-icon {
  border-radius: 100%;
  height: 2rem;

  .fa-lg {
    font-size: 1rem;
  }
}
</style>