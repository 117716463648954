<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return { };
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType" 
    v-if="isContentVisibleToUser()" 
    class="position-relative" 
    page-ambassador>
    <div class="row align-items-center text-center flex-column mx-0 header bg-gray-light py-5">
      <div v-if="content.breadcrumb" class="col-12 col-md-6">
        <breadcrumb/>
      </div>
      <img v-if="content.avatar" :alt="content.avatar.alt" :src="content.avatar.url" class="header__avatar rounded-circle"/>
      <h1 class="h5 mt-4 header__title col-12 col-md-6">{{content.title}}</h1>
      <p v-html="content.description" class="page-header__description col-12 col-md-8"></p>
      <div class="d-flex social-icons mt-2" v-if="content.social_icons">
        <a
          v-for="(link, index) in content.social_icons"
          :key="`social-icon-${index}`"
          :href="link.url.url"
          class="link-dark"
          :target="getTarget(link.url)"
        >
          <i class="mx-2" :class="link.icon"></i>
        </a>
      </div>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss" scoped>
[page-ambassador] {
  .header {
    &__avatar {
      width: 230px;
      height: 230px;
    }

    &__title {
      font-family: "Gotham";
      font-stretch: normal;
      font-style: normal;
      line-height: 1.05;
      text-transform: uppercase;
    }

    &__description {
      line-height: 1.42;
    }
  }
}
</style>
