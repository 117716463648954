<template>
  <div class="filters-container">
    <template v-if="hasCategories">
      <div
        class="category-container"
        v-for="(category, index) in innerValue"
        :key="`category-${category.title}`"
        :class="index===innerValue.length-1?'pb-0':'pb-5'"
      >
        <div class="text--divider mb-2">
          <hr>
          <span class="mx-2 bg-gray-light category-container__title text-uppercase">{{ category.title }}</span>
        </div>
        <div class="category-container__filters">
          <template v-for="(filter, idx) in category.filters">
            <filter-tag :key="`filter-${index}-${idx}`" v-model="filter.content" @input="toggleFilter"></filter-tag>
          </template>
        </div>
      </div>
    </template>
    <div v-else class="category-container__filters">
      <template v-for="(filter, index) in innerValue">
        <filter-tag :key="`filter-${index}`" v-model="filter.content" @input="toggleFilter"></filter-tag>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    hasCategories: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      innerValue: [...this.value]
    }
  },
  methods: {
    toggleFilter() {
      this.$emit('input', this.innerValue);
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal && newVal!==oldVal) {
        Vue.set(this, "innerValue", [...newVal]);
      }
    }
  }
}
</script>