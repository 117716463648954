import { fandomTranslate } from "src/modules/common_module";
import { get } from "lodash";
import { Fandom } from "./fandom_module";

class Gigya {
  /**
    * Constructor
    * @param {string} country code
    */
  constructor(country) {
    this.lang = country.replace("_", "-").toLowerCase();
    //this.syncSession();
  }

  /**
    * Check if the user has interacted with the marketing consent. A choice is generally offered when arriving from another country.
    */
  initProfiledCommunication() {
    if (Fandom.isUserRegistered()) {
      gigya.accounts.getAccountInfo({include: "preferences", callback: (response) => {
        if (parseInt(response.errorCode) === 0) {
          const lang = this.lang.includes("-") ? this.lang.split("-")[1] : this.lang;
          const profiledCommunication = response.preferences[`profiledCommunication${lang.toUpperCase()}`];
          if (!profiledCommunication) {
            const isChecked = false;
            this.editEntitlements(response, isChecked, () => {
              const args = [];
              args[2] = "modal-user-marketing-information-screenset";
              this.execute(this.configuration("registrations_marketing", args));
              $("#modal-user-marketing-information").modal("show");
            });
          }
        }
      }});
    }
  }

  /**
    * Frontend syncSession.
    */
  syncSession() {
    Fandom.executeOnce("gigya-session-verification", () => {
      gigya.accounts.getAccountInfo({ callback: (response) => {
        const isUserGigyaRegistered = parseInt(response.errorCode) === 0;
        if (Fandom.isUserRegistered() != isUserGigyaRegistered && !globalState.prevent_gigya_sync) {
          $("#modal-user-session-expired").on('hidden.bs.modal', (event) => {
            document.querySelector("body").style.opacity = 0.5;
            this.logout();
          })

          $("#modal-user-session-expired").modal("show");  
        }
      }});
    });
  }

  /**
    * Backend syncAccount.
    * @param {object} response Screen set response
    * @param {function} callback success callback
    */
  syncAccount(response, callback = (data) => {}) {
    const uid = response.UID;
    const uidSignature = response.UIDSignature;
    const uidTimestamp = response.signatureTimestamp

    if (uid) {
      Fandom.ajax({
        url: "/api/v5/gigya/registration",
        method: "POST",
        data: { UID: uid, UIDSignature: uidSignature, signatureTimestamp: uidTimestamp, lang: this.lang },
        success: (data) => {
          if (data._success && parseInt(response.errorCode) === 0) {
            callback(data);
          }
        },
        error: (jqXHR, textStatus, errorThrown) => {
          alert("error in the authentication process. Please try again or contact an administrator.");
          console.log("%cScreen set error: invalid syncAccount response", "color: #dc3545", jqXHR, textStatus, errorThrown);
        }
      });
    }
  }

  /**
    * editEntitlements edit entitlements.
    * @param {object} response Screen set response
    * @param {boolean} isChecked New value
    * @param {function} callback success callback
    */
  editEntitlements(response, isChecked = true, callback = () => {}) {
    const uid = response.UID;
    const uidSignature = response.UIDSignature;
    const uidTimestamp = response.signatureTimestamp;
    const regToken = get(response, ["requestParams", "regToken"], null);
    const email = get(response, ["requestParams", "profile", "email"], null);

    Fandom.ajax({
      url: "/api/v5/gigya/entitlements",
      method: "POST",
      data: { 
        UID: uid, 
        UIDSignature: uidSignature, 
        signatureTimestamp: uidTimestamp, 
        lang: this.lang, 
        isChecked: isChecked,
        regToken: regToken,
        email: email
      },
      success: (data) => {
        if (data._success) {
          callback();
        }
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.log("%cScreen set error: invalid editEntitlements response", "color: #dc3545", jqXHR, textStatus, errorThrown);
      }
    });
  }

  /**
    * Registration configuration (/users/sign_up)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  registration(screenSet = "KwClub-RegistrationLogin", startScreen = "gigya-register-screen", containerID = "gigya-register") {
    return {
      screenSet: screenSet,
      startScreen: startScreen,
      containerID: containerID,
      lang: this.lang,
      onAfterSubmit: (event) => {   
        let isChecked = false;
        const kwcMarketing = this.lang === "de" ? "kwcMarketingDE" : "kwcMarketing";
        document.querySelectorAll(`#${event.instanceID} .${kwcMarketing} .gigya-input-checkbox`).forEach((el) => {
          isChecked = isChecked || el.checked;
        });
        this.editEntitlements(event.response, isChecked, () => { 
          this.syncAccount(event.response, (data) => {
            window.location.href = data.after_registration_path;
          }) 
        });
      },
      onAfterScreenLoad: (event) => {
        this.bannerizeMarketingConsent(event.instanceID);
        if (event.currentScreen == "gigya-complete-registration-screen") {
          this.initEntitlements(event.instanceID, event.response);
        }
        this.updateScrollPosition("gigya-register");
      }
    };
  }

  /**
    * Login configuration (/users/sign_in)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  login(screenSet = "KwClub-RegistrationLogin", startScreen = "gigya-login-screen", containerID = "gigya-login") {
    return {
      screenSet: screenSet,
      startScreen: startScreen,
      containerID: containerID,
      lang: this.lang,
      onAfterSubmit: (event) => {
        if (event.screen == "gigya-complete-registration-screen") {
          let isChecked = false;
          const kwcMarketing = this.lang === "de" ? "kwcMarketingDE" : "kwcMarketing";
          document.querySelectorAll(`#${event.instanceID} .${kwcMarketing} .gigya-input-checkbox`).forEach((el) => {
            isChecked = isChecked || el.checked;
          });
          this.editEntitlements(event.response, isChecked, () => { 
            this.syncAccount(event.response, (data) => {
              window.location.href = data.after_registration_path;
            }) 
          });
        } else {
          if (parseInt(event.response.errorCode) === 0) {
            this.syncAccount(event.response, (data) => {
              window.location.href = data.after_registration_path;
            });
          } else if (parseInt(event.response.errorCode) === 403042) {
            document.querySelector(`#modal-login-error .modal-body`).innerHTML = fandomTranslate("gigya.error_403042");
            $("#modal-login-error").modal("show");
            console.log("%cScreen set error: login", "color: #dc3545", event.response.errorCode, event.response.errorDetails);
          }
        }
      },
      onAfterScreenLoad: (event) => {
        if (event.currentScreen == "gigya-complete-registration-screen") {
          this.bannerizeMarketingConsent(event.instanceID);
          this.initEntitlements(event.instanceID, event.response);
        }
        this.updateScrollPosition("gigya-register");
      }
    };
  }

  /**
    * registrationLite configuration (vue component)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
   registrationLite(screenSet = "KwClub-LiteRegistration", startScreen = "gigya-subscribe-with-email-screen", containerID = "gigya-newsletter") {
    let isChecked = false;
    return {
      screenSet: screenSet,
      startScreen: startScreen,
      containerID: containerID,
      lang: this.lang,
      onAfterSubmit: (event) => {
        gigya.accounts.getAccountInfo({include: "preferences", callback: (response) => {
          // Registered user requires signature
          this.editEntitlements((parseInt(response.errorCode) === 0 ? response : event.response), isChecked);
        }});
      },
      onBeforeValidation: (event) => {
        return new Promise((resolve) => {
          if (!isChecked)
            resolve({
              "form": fandomTranslate("gigya.marketing_consent_required")
            })
          else
            resolve();
        });
      },
      onAfterScreenLoad: (event) => {
        if (event.currentScreen === "gigya-subscribe-thank-you-screen") {
          document.querySelectorAll(`#${event.instanceID} .gigya-button`).forEach((el) => {
            el.addEventListener('click', (e) => {
              $(`#${event.instanceID}`).closest("[user-gigya]").remove();      
              e.preventDefault();
              e.stopPropagation();
            });
          });
        } else {
          const kwcMarketing = this.lang === "de" ? "kwcMarketingDE" : "kwcMarketing";
          document.querySelectorAll(`#${event.instanceID} .${kwcMarketing} .gigya-input-checkbox`).forEach((el) => {
            el.checked = false;
          });

          document.querySelectorAll(`#${event.instanceID} .gigya-input-checkbox`).forEach((consent) => {
            consent.addEventListener('change', (_) => {
              document.querySelectorAll(`#${event.instanceID} .gigya-input-checkbox`).forEach((el) => {
                isChecked = isChecked || el.checked;
              });
            });
          });

          this.bannerizeMarketingConsent(event.instanceID);
        }
      }
    };
  }

  /**
    * Edit configuration (vue component)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  edit(screenSet = "KwClub-ProfileUpdate", startScreen = "gigya-update-profile-screen", containerID = "gigya-registration-edit") {
    return {
      screenSet: screenSet,
      startScreen: startScreen,
      containerID: containerID,
      lang: this.lang,
      onAfterSubmit: (event) => {    
        if (event.screen === "gigya-change-password-screen") {
          $("#modal-user-changed").modal("show");
        } else {
          this.syncAccount(event.response, () => {
            $("#modal-user-changed").modal("show");
          });
        }
      },
      onAfterScreenLoad: (event) => {
        this.updateScrollPosition(event.instanceID);
      }
    }
  }

  /**
    * registrationExtra configuration (vue component)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  registrationExtra(screenSet = "KwClub-ProfileUpdate", startScreen = "gigya-update-profile-extra-screen", containerID = "gigya-registration-extra") {
    return this.basic(screenSet, startScreen, containerID, (_) => {
      $("#modal-user-changed").modal("show");
    });
  }

  /**
    * marketing configuration (vue component)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  marketing(screenSet = "KwClub-ProfileUpdate", startScreen = "gigya-marketing-preferences-screen", containerID = "gigya-marketing") {
    return {
      screenSet: screenSet,
      startScreen: startScreen,
      containerID: containerID,
      lang: this.lang,
      onAfterSubmit: (event) => {
        let isChecked = false;
        const kwcMarketing = this.lang === "de" ? "kwcMarketingDE" : "kwcMarketing";
        document.querySelectorAll(`#${event.instanceID} .${kwcMarketing} .gigya-input-checkbox`).forEach((el) => {
          isChecked = isChecked || el.checked;
        });

        gigya.accounts.getAccountInfo({include: "preferences", callback: (response) => {
          if (response.errorCode === 0) {
            this.editEntitlements(response, isChecked, () => {
              if (event.instanceID.startsWith("modal-")) {
                $(`#${event.instanceID}`).closest(".modal").modal("hide");
              } else {
                $("#modal-user-changed").modal("show");
              }
            });
          } else {
            console.log("%cScreen set error: marketing onAfterSubmit getAccountInfo", "color: #dc3545", response.errorCode, response.errorDetails);
          }
        }});
      },
      onAfterScreenLoad: (event) => {  
        gigya.accounts.getAccountInfo({include: "preferences", callback: (response) => {
          if (response.errorCode === 0) {
            this.initEntitlements(event.instanceID, response);
          } else {
            console.log("%cScreen set error: marketing onAfterScreenLoad getAccountInfo", "color: #dc3545", response.errorCode, response.errorDetails);
          }
        }});
        
        this.bannerizeMarketingConsent(event.instanceID);        
      }
    }
  }
  
  /**
    * passwordForgot configuration (/users/password/new)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  passwordForgot(screenSet = "KwClub-RegistrationLogin", startScreen = "gigya-forgot-password-screen", containerID = "gigya-password-forgot") {
    return this.basic(screenSet, startScreen, containerID);
  }

  /**
    * passwordReset configuration (/users/password/edit)
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  passwordReset(screenSet = "KwClub-RegistrationLogin", startScreen = "gigya-reset-password-screen", containerID = "gigya-password-reset") {
    return this.basic(screenSet, startScreen, containerID);
  }

  /**
    * basic empty configuration
    * @param {string} screenSet code
    * @param {string} startScreen code
    * @param {string} containerID div id 
    */
  basic(screenSet, startScreen, containerID, callback = () => {}) {
    return {
      screenSet: screenSet,
      startScreen: startScreen,
      containerID: containerID,
      lang: this.lang,
      onAfterSubmit: (event) => {
        callback(event);
      }
    }
  }

  /**
    * Execute screenset
    * @param {object} configuration Gigya configuration
    */
  execute(configuration) {
    const until = (resolve) => {
      if (window.gigya_ready) resolve();
      else setTimeout(() => until(resolve), 100);
    }
    const promise = new Promise(until);
    promise.then(() => { gigya.accounts.showScreenSet(configuration) });
  }

  /**
    * Initialize entitlements checkbox
    * @param {string} instanceID
    * @param {object} response Screen set response 
    */
  initEntitlements(instanceID, response) {
    Fandom.ajax({
      url: "/api/v5/gigya/entitlements",
      method: "GET",
      data: { UID: response.UID, lang: this.lang },
      success: (data) => {
        const kwcMarketing = this.lang === "de" ? "kwcMarketingDE" : "kwcMarketing";
        document.querySelectorAll(`#${instanceID} .${kwcMarketing} .gigya-input-checkbox`).forEach((el) => {
          el.checked = data.isChecked;
        });
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.log("%cScreen set error: invalid editEntitlements response", "color: #dc3545", jqXHR, textStatus, errorThrown);
      }
    });
  }

  /**
    * bannerizeMarketingConsent move marketing consent into a bootstrap banner
    * @param {object} instanceID
    */
  bannerizeMarketingConsent(instanceID) {
    document.querySelectorAll(`#${instanceID} .js-toggle-info`).forEach((iconConsent) => {
      iconConsent.closest("label").addEventListener('click', (e) => {
        const consent = document.querySelector(`#${instanceID} .js-gigya-toggle-content`).innerHTML;
        document.querySelector(`#modal-marketing-information .modal-body`).innerHTML = consent;
        $("#modal-marketing-information").modal("show");
    
        e.preventDefault();
        e.stopPropagation();
      });
    });
  }

  /**
    * logout
    */
  logout() {
    gigya.accounts.logout({ 
      callback: () => {
        // Fandom sign out
        window.location.href = Fandom.applyContextToUrl("/users/sign_out");
      } 
    });
  }

  /**
    * verifySession
    * @param {function} callback after verify callback
    */
  verifySession(callback) {
    gigya.accounts.session.verify({ callback: 
      (event) => {
        callback(event);
      }
    });
  }

  /**
    * updateScrollPosition change screen position after screen set change scenario
    * @param {string} instanceID
    */
  updateScrollPosition(instanceID) {
    document.querySelectorAll(`#${instanceID} a[href='javascript:void(0)']`).forEach((el) => {
      el.addEventListener('click', (e) => {
        const position = $(`#${instanceID}`).offset().top - ($("#main-navbar").outerHeight() || 0);
        window.scrollTo(0, position);
      });
    });
  }

  /**
    * Return the configuration name by RoR controller in devise environment
    * @param {string} controller RoR controller
    * @param {array} args configuration arguments
    */
  configuration(controller, args = []) {
    return {
      "registrations_new": this.registration(...args),
      "registrations_edit": this.edit(...args),
      "registrations_extra": this.registrationExtra(...args),
      "registrations_marketing": this.marketing(...args),
      "registrations_lite": this.registrationLite(...args),
      "sessions_new": this.login(...args),
      "passwords_new": this.passwordForgot(...args),
      "passwords_edit": this.passwordReset(...args)
    }[controller]
  }

}

export { Gigya };