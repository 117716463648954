<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  data: function() {
    return { 

    };
  },
  mounted() {
    this.content.accordion.forEach((item) => {
      Vue.set(item, "collapsed", true);
    });

  },
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    toggleItem(index) {
      Vue.set(this.content.accordion[index], "collapsed", !this.content.accordion[index]["collapsed"]);
    }
  },
  computed: { },
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :id="anchorId"
    v-if="isContentVisibleToUser()" 
    :class="contentType"
    class="pb-4" 
    stripe-accordion-component
  >
    <div class="container themed-item" :style="theme">
      <h4 class="mb-5" v-if="content.title">{{content.title}}</h4>
      <div class="separator w-100 bg-primary"></div>
      <div v-for="(item, index) in content.accordion" :key="index" :ref="'accordion-'+index">
        <a
          :href="`#item-${index}-${content.$id}`"
          :aria-controls="`item-${index}-${content.$id}`"
          class="deco-none w-100 collapsed accordion"
          data-toggle="collapse"
          @click="toggleItem(index)">
          <span class="font-lg item-title d-inline-block ml-3 my-4 align-items-center">{{item.title}}</span>
        </a>
        <div class="collapse" :id="`item-${index}-${content.$id}`">
          <p class="mb-4" v-html="item.description"></p>
        </div>
        <div class="separator w-100 bg-primary"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-accordion-component] {

  .separator {
    height: 1px;
  }

  a.accordion.collapsed::before {
    content: "+";
    color: var(--primary);
  }

  a.accordion::before {
    content: "-";
    color: var(--primary);
  }
}
</style>
