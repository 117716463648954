<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { map, get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return {
      originals: {},
      init: !Fandom.isUserRegistered(),
      error: !Fandom.isUserRegistered()
    };
  },
  computed: {
    username() {
      return get(globalState.pageModel, ["user", "first_name"], "");
    }
  },
  methods: {
    headingContent(original) {
      return { 
        title: original.title, 
        width: 12,
        padding_bottom: 3,
        background_color: this.content.background_color,
        text_align: "center"
      }
    },
    unsetContent() {
      return { ...this.content.unset_content, ...{ background_color: this.content.background_color } };
    },
    initOriginals() {
      if (Fandom.isUserRegistered())
        Fandom.post({
          data: { 
            main_content_name: this.containerContent.name, 
            main_content_record_id: this.content.$id,
            exclude_content_ids: map(globalState.pageModel.name_to_content, c => c["id"])
          },
          url: "/api/v5/get_user_originals_contents",
          success: (data) => {
            if (data.error) {
              this.error = true;
              this.init = true;
            } else {
              Fandom.getContentsCallback(data.main_content_name, data, (data) => {
                this.originals = Fandom.getContent(data.main_content_name).children;
                this.init = true;
              })
            }
          }
        });
    }
  }
};
</script>

<template>
  <intersect @enter.once="initOriginals()">
    <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" :style="theme" :id="anchorId" user-originals-component>
      <div class="themed-item">
        <div v-if="username" class="w-100 text-center pt-5">
          <i class="h2">{{ft("globals.welcome", { name: username })}}</i>
        </div>
        <template v-if="init && !error">
          <template v-for="original in originals">
            <stripe-text :content="headingContent(getContent(original))" :opt-container-content="containerContent"></stripe-text> 
            <grid-fixed :content="{ ...getContent(original), ...{ enable_load_more: false, padding_bottom: 0, background_color: content.background_color } }" :opt-container-content="containerContent"></grid-fixed>
          </template>
        </template> 
        <template v-else-if="init && error">
          <stripe-text :content="unsetContent()" :opt-container-content="containerContent"></stripe-text> 
        </template>
        <div class="py-5 w-100 text-center" v-if="!init">
          <i class="fad fa-spinner fa-spin text-primary fa-pulse fa-3x"></i>
        </div>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss">
[user-originals-component] {

}
</style>