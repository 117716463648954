<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
    }
  },
  methods: {
    getContentURL: Fandom.getContentURL
  },
  computed: {
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" tile-search-component>
    <a class="deco-none" :href="getContentURL(content)">
      <div class="row align-items-center">
        <div class="col-auto mb-3 mb-md-0"><div class="bg bg-cover-center ar-16by9" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div></div>
        <div class="col-12 col-md">
          <div class="text-uppercase small text-primary pb-2" v-if="getDecorator(content, 'default')">
            {{getDecorator(content, "default").title}}
          </div>
          <div class="h6 mb-0" v-if="content.title">{{content.title}}</div>
        </div>
      </div>
    </a>
    <hr class="mb-0">
  </div>
</template>

<style lang="scss" scoped>
[tile-search-component] {
  .bg {
    width: 160px;
  }
}
</style>


