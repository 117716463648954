// Update the page status based on the active page. "content" current content, "content_filtering" used in get_contents params and "append" 
// indicates whether the new contents are added to the present ones or not.
function updatePagination(content, content_filtering, append) {
  content.page = append ? (content.page || 1) + 1 : 1;
  const historyState = { name_to_content: { ...globalState.pageModel.name_to_content }};

  let queryString = ""; 
  queryString = Fandom.updateQueryStringParameter(queryString, "page_content_name", content.name);
  queryString = Fandom.updateQueryStringParameter(queryString, "page", content.page);

  if (content_filtering && content_filtering.length > 0) {
    const activeFiltersSerialized = JSON.stringify(content_filtering);
    queryString = Fandom.updateQueryStringParameter(queryString, "page_content_filtering", activeFiltersSerialized);
  } else {
    // nothing to do
  }

  history.pushState(historyState, '', `${window.location.pathname}${queryString}`);
}

// Returns the seo url of the next page.
function paginationSEOUrl(content, content_filtering) {
  const page = parseInt(content.page) || 1

  let queryString = ""; 
  queryString = Fandom.updateQueryStringParameter(queryString, "page_content_name", content.name);
  queryString = Fandom.updateQueryStringParameter(queryString, "page", page + 1);

  if (content_filtering && content_filtering.length > 0) {
    const activeFiltersSerialized = encodeURIComponent(JSON.stringify(content_filtering));
    queryString = Fandom.updateQueryStringParameter(queryString, "page_content_filtering", activeFiltersSerialized);
  } else {
    // nothing to do
  }

  return window.location.pathname + `${queryString}`; 
}

// Set the initial state of history as the name_to_content.
// It is necessary to be able to return to the initial state after back browser command.
function initHistory() {
  if (!history.state) {
    const historyState = { name_to_content: { ...globalState.pageModel.name_to_content }};
    history.replaceState(historyState, "");
  }
}

const seoGrid = {
  initHistory,
  updatePagination,
  paginationSEOUrl
}

export {
  seoGrid
};