<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";
import { Gigya } from 'src/modules/gigya_module';

export default {
  data: function() {
    return {
      sidebarOpen: false,
      sidebarSide: "left",
      showSearchLayer: false,
      searching: false,
      searchText: ""
    };
  },
  mixins: [fandomBasicMixin],
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    isUserRegistered: Fandom.isUserRegistered,
    logout() {
      const _gigya = new Gigya("IT");
      _gigya.logout();
    },
    toggleSidebar(side="left") {
      if (this.isViewportMd) {
        this.sidebarSide = side;
        this.sidebarOpen = true;
      }
    },
    handleAfterEnter() {
      $(".search-input").focus();
      this.showSearchLayer = true;
    },
    search() {
      window.location.href = `${this.resultUrl}?q=${encodeURI(this.searchText)}`;
    }
  },
  computed: {
    user() {
      return get(this.globalState.pageModel, ["user"], {});
    },
    userInitials() {
      return `${this.user.username?.[0] ?? ""}`.toUpperCase();
    },
    resultUrl() {
      return get(this.content, ["search_result_url","url"], "search");
    },
    isViewportMd() {
      return globalState.viewport.sm || globalState.viewport.xs;
    },
    userAvatarUrl() {
      return get(this.user, ["avatar", "url"], false);
    },
    avatarStyle() {
      if (this.userAvatarUrl) {
        return {
          backgroundImage: this.getBackgroundUrl(this.userAvatarUrl)
        }
      }

      return {}
    }
  },
};
</script>

<template>
  <div class="sticky-top bg-white" id="main-navbar">
    <div class="w-100 d-flex justify-content-end justify-content-md-between align-items-center position-relative px-3 border-bottom border-gray-primary" header>
      <div class="header-left d-none d-md-flex align-items-center px-4 mx-3" v-if="content.support">
        <a class="w-100 text-uppercase deco-none small" :href="content.support.url" :target="getTarget(content.support)" :rel="getRelNoFollow(content.support)">
          <i class="ki ki-support fa-lg pr-2"></i>
          {{ content.support.title }}
        </a>
      </div>
      <div class="px-3 d-flex justify-content-md-center align-items-center logo-container">
        <a href="/" v-if="globalState.isHomepage">
          <h1 class="m-0 p-0" style="line-height: 0;">
            <img class="logo" v-if="content.logo" :src="content.logo.url" :alt="content.logo.alt">
          </h1>
        </a>
        <a href="/" v-else>
          <img class="logo" v-if="content.logo" :src="content.logo.url" :alt="content.logo.alt">
        </a>
      </div>
      <div class="header-right px-md-4 mx-md-3 d-flex align-items-center">
        <div class="h-100 pr-3 mr-2 d-md-block d-none">
          <a 
            v-for="(item, index) in content.top_links"
            :key="`top-link-${index}`"
            :href="item.url.url"
            :target="getTarget(item.url)"
            :rel="getRelNoFollow(item.url)"
            class="deco-none text-uppercase small"
          >
            <span class="d-lg-inline-block d-none">{{item.url.title}}</span>
            <i v-if="item.icon" :class="item.icon" class="fa-lg pl-2"></i>
          </a>
        </div> 
        <a 
          v-if="content.profile_menu" 
          :href="isViewportMd ? '' : '#user-dropdown'" 
          class="position-relative logged-user-dropdown-toggle collapsed text-decoration-none"
          data-toggle="collapse"
          @click="toggleSidebar('right')"
        >
          <div 
            class="bg-cover-center avatar d-flex justify-content-center align-items-center"
            :style="avatarStyle" 
            v-if="isUserRegistered()"
          >
            <span v-if="!userAvatarUrl" class="text-white">{{userInitials}}</span>
          </div>
        </a>
        <div class="h-100 pr-md-3" v-if="!isUserRegistered()">
          <a href="/users/sign_in">{{ft("globals.login.login")}}</a>
        </div>
        <i class="fal fa-search ml-3 cursor-pointer fa-lg" @click="searching = true"></i>
      </div>
      <transition enter-active-class="animated faster slideInRight" leave-active-class="animated faster slideOutRight" @after-enter="handleAfterEnter">
        <div class="h-100 position-absolute search-stripe d-flex align-items-center bg-magenta mx-3" v-if="searching">
          <div class="pl-md-4 mx-md-3 flex-grow-1">
            <input type="text" class="w-100 pl-3 py-1 search-input" :placeholder="ft('globals.advanced_search.search')" v-model="searchText" @keyup.enter="search">
          </div>
          <div class="mx-3">
            <i class="fal fa-search cursor-pointer fa-lg" @click="search"></i>
          </div>
          <div class="pr-md-4 mx-md-3" @click="searching = false; showSearchLayer = false;">
            <i class="ki ki-close cursor-pointer"></i>
          </div>
        </div>
      </transition>
      <div class="position-absolute bg-primary mr-3 mr-md-5 collapse" id="user-dropdown" v-if="content.profile_menu && isUserRegistered()">
        <div
          class="user-link px-3 py-2 w-100 text-right border-bottom"
          v-for="(item, index) in content.profile_menu"
          :key="index"
          >
          <a :href="item.url.url" class="text-white text-uppercase small" :target="getTarget(item.url)" :rel="getRelNoFollow(item.url)">
            {{item.url.title}}
          </a>
        </div>
        <div class="user-link px-3 py-2 w-100 text-right">
          <a 
            href="/users/sign_out" 
            @click.prevent="logout()"
            class="text-white text-uppercase small" 
          >
            logout
          </a>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="w-100 h-100 position-fixed" v-if="showSearchLayer" layer-light></div>
    </transition>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="w-100 h-100 position-fixed" v-if="isViewportMd && sidebarOpen" layer-dark></div>
    </transition>
    <sidebar
      :show="isViewportMd && sidebarOpen"
      :side="sidebarSide"
      :menu-items="sidebarSide==='left' ? content.menu : content.profile_menu"
      :content="content"
      :opt-container-content="content" 
      @sidebarClose="sidebarOpen = false"
    >
      <template #sidebar-header v-if="sidebarSide==='right'">
        <template v-if="!user || user.anonymous">
          <div class="px-3 py-2 border-top w-100">
            <div class="mx-3 my-1">
              <a class="link-dark" href="users/sign_in">
                {{ ft("globals.login.login") }}
              </a>
            </div>
          </div>
          <div class="px-3 py-2 w-100 bg-red-cabbage">
            <div class="mx-3 my-1">
              <a class="link-white" href="users/sign_up">
                {{ ft("globals.login.sign_up") }}
              </a>
            </div>
          </div>
        </template>
        <div class="px-3 py-4 border-bottom border-gray-primary w-100" v-else-if="content.profile_menu" sidebar-item-component>
          <div class="mx-3 my-1 text-uppercase subitem d-flex">
            <div 
              class="bg-cover-center avatar d-flex justify-content-center align-items-center"
              :style="avatarStyle"
              v-if="isUserRegistered()"
            >
              <span v-if="!userAvatarUrl" class="text-lg text-white">{{userInitials}}</span>
            </div>
            <a class="pl-3 text-dark collapsed d-flex justify-content-between align-items-center">
              {{ user.username }}
            </a>
          </div>
        </div>
      </template>
    </sidebar>
    <navbar
      :content="content"
      :opt-container-content="content"
      @toggle-sidebar="toggleSidebar"
    ></navbar>
  </div>
</template>

<style lang="scss">
[header] { 
  height: $header-height;
  z-index: $zindex-menu-item + 2;
  background-color: $magenta;
  color: $white;

  a {
    color: $white;
  }

  @include media-breakpoint-down(sm) {
    height: $header-height-mobile;
  }

  .logo {
    max-height: 6rem;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .logo-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header {
    &-right, &-left {
      z-index: 1;
    } 
  }

  .search > div:not(.avatar) {
    border-right: 1px solid $dark;

    & > a {
      color: $dark;
    }
  }

  .search-stripe {
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .search-input {
    border-left: 1px solid $dark;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;

    &:focus {
      outline: none;
    }
  }

  #user-dropdown {
    top: $header-height;
    width: 15rem;
    right: 0;

    .user-link a:hover {
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .logo {
      max-height: 3.3rem;
    }
  }
}

.avatar {
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
  z-index: 1;
  background-color: $primary;
  border: 1px solid $gray-600;
}

.header-right {
  .avatar {
    @include media-breakpoint-down(sm) {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
}

[layer-dark] {
  background-color: rgba($black, .35);
  z-index: $zindex-menu-item + 2;
  top: 0;
}

[layer-light] {
  background-color: rgba($white, .5);
  z-index: $zindex-menu-item + 1;
  top: 0;
}
</style>
