<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    title: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "dark"
    },
    backgroundColor: {
      type: String,
      default: "gray-primary"
    },
    name: {
      type: String,
      default: "recipeIngredients"
    },
    ingredients: {
      type: Array,
      default: () => []
    },
    allergens: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    idSelector() {
      return `#${this.name}`;
    },
    backgroundClass() {
      return `bg-${this.backgroundColor}`;
    },
    textClass() {
      return `text-${this.color}`;
    }
  }
};
</script>

<template>
  <div class="recipe__ingredients">
    <div class="row px-3 px-lg-0 pl-lg-3 py-lg-4 py-3">
      <div class="text-white font-xs p-3 w-100" :class="backgroundClass">
        <div>{{title}}</div>
      </div>
      <div class="col-12 px-0 border-bottom border-gray-dark">
        <ul v-if="ingredients.length > 0" class="ingredients p-0 mb-0">
          <li v-for="(ingredient, index) in ingredients" class="d-block ingredient" :key="`ingredient-${index}`">
            <div class="py-3 px-3 border-gray-dark" :class="[textClass, {'border-top': index > 0}]">{{ingredient.quantity}} {{ingredient.title}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fa-chevron-up {
  transition: transform 0.75s;
}

.collapsed {
  .fa-chevron-up {
    transform: rotate(-180deg);
  }
}

.ingredients{
  &-head {
    cursor: pointer;
  }
}

.collapsing {
  transition: all 0.65s;
}
</style>
