<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    getContentStyle () {
      var styleObject = {};
      if (Fandom.exists(this.content.background_color)) {
        styleObject["background-color"] = this.content.background_color;
      }
      if (Fandom.exists(this.content.background_image)) {
        styleObject["background-image"] = this.getContentResponsiveImageURL("background_image");
      }
      if (Fandom.exists(this.content.text_color)) {
        styleObject["color"] = this.content.text_color;
      }
      return styleObject;
    }
  },
  computed: {
    submitUrl() {
      return this.ugcContentName ? `/simple_form/course/${this.ugcContentName}` : "/simple_form/course";
    },
    ugcContentName() {
      return Fandom.getQueryStringParams().ugc_content_name || "";
    }
  }
};
</script>

<template>
  <div :id="content.name" v-easyadmin="easyadminId" :class="contentType" class="bg-cover-center themed-item" v-if="isContentVisibleToUser()" :style="[theme, getContentStyle()]" simple-form-component>
    <div class="container">
      <div class="row justify-content-center align-items-center py-5">
        <div class="col-sm-12 col-md-8 col-sm-6">
          <text-section :content="content" :opt-container-content="optContainerContent"/>
          <generic-form :ugc-content-preview="true" :ugc-content-name="ugcContentName" :form-url="submitUrl" :content="content" :opt-container-content="containerContent"></generic-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>
