<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash-es';

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getTarget: Fandom.getTarget,
    recaptchaIsActive() {
      return !!globalState.pageModel.aux.recaptcha_enterprise;
    }
  },
  computed: {
    headerMainMenu() {
      return get(this.pageModel, 'layout.header.menu', [])
    }
  }
};
</script>

<template>
  <div footer-component>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="content"></component>
    <div class="container-fluid border-top px-3 px-lg-5">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto my-4 d-lg-block d-none">
          <span v-if="content.disclaimer" class="disclaimer small">{{content.disclaimer}}</span>
        </div>
        <div class="header-link navbar-link-container col-12 col-lg-auto py-3 pb-lg-0 d-flex align-items-center justify-content-center flex-column flex-md-row flex-wrap">
          <a
            class="text-dark pb-3 navbar-link position-relative small text-decoration-none"
            v-for="(item, index) in content.menu"
            :href="item.url.url"
            :target="getTarget(item.url)"
            :key="`bottom-${index}`"
            >
            {{item.url.title}}
          </a>
        </div>
        <div class="py-3 py-lg-0 social-container col-12 col-lg-auto d-flex align-items-center justify-content-center mt-4 mt-lg-0">
          <a
            v-for="(icon, iconIndex) in content.social_icons"
            :key="`navbar-icon-${iconIndex}`"
            :href="icon.url.url"
            class="social-icon d-flex align-items-center justify-content-center text-decoration-none px-2"
            :target="getTarget(icon.url)"
            >
            <i :class="icon.icon"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="container-fluid px-3 px-lg-5" v-if="recaptchaIsActive()">
      <div class="row">
        <div class="col-12">
          <p class="small" >
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>
        </div>
      </div>  
    </div>  
  </div>
</template>

<style lang="scss">
[footer-component] {
  height: $footer-height;

  .social-container {
    .social-icon {
      color: $dark;
      transition: color .25s;

      &:hover {
        color: $primary;
      }
    }
  }

  .navbar-link {
    color: $black;
    transition: color $animation-time-fast;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      color: $primary;
    }
  }

  @include media-breakpoint-down(lg) {
    .social-container {
      order: 0;
      font-size: 1.5rem;
    }

    .header-link {
      order: 1;
    }

    .navbar-link-container {
      order: 2;

      .navbar-link {
        margin: 0;
        padding: 0 .7rem;
      }
    }
  }
}
</style>
