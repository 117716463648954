<script>
import { each } from "lodash";

export default {
  data() {
    return {
      title: null,
      breadcrumb: []
    };
  },
  methods: {
    applyContextToUrl: Fandom.applyContextToUrl
  },
  computed: {
    current() {
      const mainContentName = globalState.pageModel.main_content_name;
      return globalState.pageModel.name_to_content[mainContentName];
    }
  },
  mounted() {
    each(globalState.pageModel.breadcrumb, content => {
      if (Fandom.exists(content.title)) {
        this.breadcrumb.push({ title: content.title, url: content.slug });
      }
    });
  }
};
</script>

<template>
  <div class="d-none d-md-flex w-100 px-3 pt-0" v-if="current.slug != '/'" breadcrumb-component>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb d-inline-block py-0 my-0 px-4 mx-3 small">
        <li class="breadcrumb-item"><a :href="applyContextToUrl('/')">Home</a></li>
        <li v-for="(b, index) in breadcrumb" class="breadcrumb-item active" v-if="b.url" aria-current="page" :key="`breadcrumb-${index}`">
          <a :href="applyContextToUrl(b.url)">{{b.title}}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">{{current.title}}</li>
      </ol>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
[breadcrumb-component] {
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  height: 38px;

  .breadcrumb {
    background-color: $white;
    font-family: $gotham-font;
    font-size: $sub-sup-font-size;
    line-height: 2.5;
    text-transform: uppercase;

    li {
      display: inline;
    }

    *, .breadcrumb-item > a, .breadcrumb-item + .breadcrumb-item::before {
      color: $dark !important;
      font-weight: 300;
    }

    &-item {
      text-transform: initial;
      font-weight: normal !important;

      &:last-child {
        font-weight: 600;
        color: $dark !important;
      }

      + .breadcrumb-item::before {
        content: ">";
      }
    }
  }
}
</style>
