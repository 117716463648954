<template>
  <div
    v-easyadmin="easyadminId"
    :class="[contentType]"
    class="modal fade"
    id="modal-form"
    tabindex="-1"
    modal-form-component
  >
    <div class="modal-dialog" :class="sizeClass">
      <div class="modal-content">
        <div class="modal-body">
          <div class="close-icon p-3">
            <i class="ki ki-close fa-2x cursor-pointer" @click="closeModal"></i> 
          </div>
          <component 
            v-if="content.form"
            :is="form.layout"
            :content="form"
            class="h-100 d-flex justify-content-center align-items-center"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
    }
  },
  mounted() {
    $('#modal-form').on('shown.bs.modal', () => {
      disableBodyScroll(this.$el);
    })
  },
  methods: {
    closeModal() {
      clearAllBodyScrollLocks();
      $('#modal-form').modal("hide")
    },
  },
  computed: {
    form() {
      return this.getContent(this.content.form.content);
    },
    sizeClass() {
      return this.content.size || 'modal-full'
    }
  }
}
</script>

<style lang="scss" scoped>
[modal-form-component] {
  .modal-dialog {
    &.modal-full {
      position: fixed;
      height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      margin: 0;

      .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>