<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { debounce, isEmpty } from "lodash";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: {
    VueTagsInput
  },
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      submitInProgress: false,
      formErrors:{},
      searchTagQuery: '',
      maxTags: 10,
      autocompleteIngredientsContent: [],
      recipeInfo: {
        accessories: [''],
        course: [],
        description: null,
        image: null,
        ingredients: [{quantity: null, text: null},{quantity: null, text: null}],
        ingredientsTag: [],
        products: [''],
        steps: ['', ''],
        title: null,
        info: { quantity: null },
        time: { cooking_time: null, resting_time: null, preparation_time: null },
      }
    };
  },
  methods: {
    submitRecipeUgc() {
      this.submitInProgress = true;
      Fandom.ajax({
        methods: "post",
        url: "/api/v5/upload_recipe_ugc",
        processData: false,
        contentType: false,
        data: this.getRecipeDataFromModel(),
        success: (data) => {
          if (!data.errors) {
            if (this.content.thank_you_page && this.content.thank_you_page.url) {
              window.location.href = this.content.thank_you_page.url;
            }
          } else {
            Vue.set(this, "formErrors", data.errors);
          }
          this.submitInProgress = false;
        },
        error: () => {
          this.submitInProgress = false;
        },
      });
    },
    getRecipeDataFromModel() {
      const formData = new FormData();
      for (let [key, value] of Object.entries(this.recipeInfo)) {
        if(key == "image" || typeof(value) == "string") {
          formData.append(`user_upload_interaction[${key}]`, value);
        } else {
          if (value) {
            formData.append(`user_upload_interaction[${key}]`, JSON.stringify(value));
          }
        }
      }

      formData.append("content_id", this.content.id);
      formData.append("template_name", this.content.ugc_template);
      formData.append("interaction_id", this.content.upload_interaction.interaction.id);

      return formData;
    },
    onAttachmentChange(event, fieldName) {
      Vue.set(this.recipeInfo, fieldName, event.target.files[0]);
    },
    selectCourse(courseName) {
      Vue.set(this.recipeInfo.course, 0, courseName);
    },
    isAddableToRecipeInfo(name, minElementsNumber = 1) {
      const recipeData = this.recipeInfo[name];
      if (recipeData.length < minElementsNumber) {
        return false;
      }

      return recipeData.reduce((acc, item) => {
        if (typeof item === "string") {
          return acc && item;
        } else if (typeof item === "object") {
          return acc && Object.values(item).reduce((isNotEmpty, item)=>{return isNotEmpty && !isEmpty(item)}, true)
        }
      }, true);
    },
    isProductAddable(pName) {
      let products = this.recipeInfo.products;
      return (products.indexOf(pName) > -1) ? false : true;
    },
    isAccessoryAddable(aName) {
      let accessories = this.recipeInfo.accessories;
      return (accessories.indexOf(aName) > -1) ? false : true;
    },
    addStep() {
      if(this.isAddableToRecipeInfo("steps", 2)) {
        this.recipeInfo.steps.push('');
      }
    },
    addNewIngredientField() {
      if(this.isAddableToRecipeInfo("ingredients", 2)) {
        this.recipeInfo.ingredients.push({quantity: null, text: null});
      }
    },
    removeStep() {
      let stepArray = this.recipeInfo.steps;
      if (stepArray.length > 2) {
        stepArray.pop();
      }
    },
    addProduct(pIndex) {
      let products = this.recipeInfo.products;
      if(products[pIndex]) {
         products.push('');
      }
    },
    addAccessory(aIndex) {
      let accessories = this.recipeInfo.accessories;
      if(accessories[aIndex]) {
        accessories.push('');
      }
    },
    removeRecipeInfoElement(elName, index) {
      this.recipeInfo[elName].splice(index, 1);;
    },
    debounceAutocomplete: debounce(function() { this.autocompleteGetIngredients() }, 500),
    autocompleteGetIngredients() {
      const data = {
        layout: "system-refs",
        layout_extra_field_name: "$empty",
        content_filtering: [["ingredients"]],
        search_text: this.searchTagQuery,
      };

      Fandom.post({
        data,
        url: "/api/v5/get_ingredients_list",
        success: this.formatTagsContent
      });
    },
    formatTagsContent(data) {
      if (data.result && data.result.main_content_name) {
        const contentName = data.result.main_content_name;
        const childrenNames = data.result.name_to_content[contentName].children;
        if (childrenNames) {
          this.autocompleteIngredientsContent = childrenNames.map((childrenName) => {
            const ingredient = data.result.name_to_content[childrenName];
            return {
              text: ingredient.title,
              name: ingredient.name,
              title: ingredient.title,
              id: ingredient.id
            }
          });
        } else {
          this.autocompleteIngredientsContent = [];
        }
      }
    },
    handleTagsChanged(tags) {
      this.recipeInfo.ingredientsTag = tags;
    },
    getTagChildrenInfo(mainTagName) {
      const childrenInfo = [];
      if(mainTagName && this.getContent(mainTagName)) {
        let childrenNames = this.getContent(mainTagName).children;
        if(childrenNames) {
          for(let i = 0; i < childrenNames.length; i++) {
            let childrenContent = this.getContent(childrenNames[i]);
            childrenInfo.push(childrenContent);
          }
        }
      }
      return childrenInfo;
    }
  },
  computed: {
    courses() {
      return this.getTagChildrenInfo(this.content.courses);
    },
    accessories() {
      return this.getTagChildrenInfo(this.content.accessories);
    },
    products() {
      return this.getTagChildrenInfo(this.content.products);
    },
    uploadImagePlaceHolder() {
      return (this.recipeInfo.image && this.recipeInfo.image.name) ? this.recipeInfo.image.name : this.ft("globals.ugc_recipe.upload_placeholder")
    },
    contentStyle() {
      return {
        "background-color": this.content.background_color,
        "--custom-file-label": `'${this.ft("globals.ugc_recipe.browse")}'`
      };
    },
  },
  watch: {
    searchTagQuery(newVal, oldVal) {
      if (this.$refs.ingredients) // workaround in mouse selection scenario
        this.$refs.ingredients.focused = true;
      
      if (newVal !== oldVal && newVal.length > 0) {
        this.debounceAutocomplete();
      }
    }
  }
}

</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :style="contentStyle" recipe-upload >
    <div class="ugc-recipe-header container-fluid">
      <div class="row py-4">
        <div class="col-12 text-center">
          <h1>{{content.title}}</h1>
        </div>
      </div>
    </div>
    <div class="ugc-recipe-form container-fluid">
      <form @submit.prevent="submitRecipeUgc()">
        <div class="row justify-content-center py-4">
          <div class="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
            <div class="row">
              <!-- Title  -->
              <div class="col-12 pt-3 pb-4">
                <label for="title" class="text-xs text-uppercase" :class="[formErrors.title ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.title")}}*</label>
                <input type="text" :placeholder="ft('globals.ugc_recipe.recipe_title_placeholder')" name="title" class="p-2 form-control w-100" v-model="recipeInfo.title" required>
              </div>
              <!-- Description  -->
              <div class="col-12 pt-3 pb-4">
                <label for="description" class="text-xs text-uppercase" :class="[formErrors.description ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.description")}}*</label>
                <textarea :placeholder="ft('globals.ugc_recipe.describe_your_recipe')" name="description" row="4" class="p-3 form-control w-100" v-model="recipeInfo.description" required></textarea>
              </div>
              <!-- Courses  -->
              <a href="#" id="courses" class="hidden-anchor"></a>
              <div class="col-12 pt-3 pb-4">
                <label class="text-xs text-uppercase" :class="[formErrors.course ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.dishes")}}*</label>
                 <div class="d-flex flex-wrap">
                  <div v-for="(course, index) in courses" :key="`course-${index}`" class="course-container p-3" :class="[recipeInfo.course[0] == course.name ? 'selected': '']" @click="selectCourse(course.name)">
                    <div class="filter-url d-flex align-items-center justify-content-center">
                      <i class="filter-icon ki" :class="`ki-${course.icon}`"></i>
                    </div>
                    <div class="text-xs text-uppercase text-center pt-3">{{course.title}}</div>
                  </div>
                </div>
              </div>
              <!-- Quantity -->
              <a href="#" id="quantity" class="hidden-anchor"></a>
              <div class="col-12 pt-3 pb-4">
                <label class="text-xs text-uppercase" :class="[formErrors.quantity ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.number_of_portions")}}</label>
                <select v-model="recipeInfo.info.quantity" class="custom-select form-control w-50" required >
                  <option disabled selected value="">{{ft("globals.ugc_recipe.number_of_portions_placeholder")}}</option>
                  <option v-for="index in 16" :value="index" :key="`quantity-${index}`">
                    {{index}} {{index == 1 ? ft("globals.ugc_recipe.portion") : ft("globals.ugc_recipe.portions")}}
                  </option>
                </select>
              </div>
              <!-- Ingredients -->
              <a href="#" id="ingredients" class="hidden-anchor"></a>
              <div class="col-12 pt-3 pb-4">
                <label class="text-xs text-uppercase" :class="[formErrors.ingredients ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.ingredients")}}*</label>
                <div v-for="(ingredient, index) in recipeInfo.ingredients" :key="`ingredients-${index}`" class="ingredient-container pb-3 d-flex">
                  <div class="ingredient-quantity d-flex pr-2">
                    <input type="text" v-model="recipeInfo.ingredients[index].quantity" placeholder="100g" name="ingredient_quantity" class="p-3 form-control w-100" required>
                  </div>
                  <div class="ingredient-type d-flex pl-2">
                    <input type="text" :placeholder="ft('globals.ugc_recipe.ingredients_placeholder')" v-model="recipeInfo.ingredients[index].text" name="ingredient_text" class="p-3 form-control w-100" required>
                  </div>
                  <div class="ingredient-actions d-flex align-items-center px-3">
                    <i v-if="recipeInfo.ingredients[index] && recipeInfo.ingredients.length != (index+1) && index != 0" class="fal fa-minus-circle" @click="removeRecipeInfoElement('ingredients', index)"></i>
                    <i v-else-if="index != 0" class="fal fa-plus-circle" :class="{'disabled': !isAddableToRecipeInfo('ingredients', 2)}" @click="addNewIngredientField"></i>
                    <div v-else class="icon-placeholder"></div>
                  </div>
                </div>
                <label class="mt-3">{{ft('globals.ugc_recipe.ingredients_tags_title')}}</label>
                <div class="w-100 pb-3">
                  <vue-tags-input 
                    :placeholder="ft('globals.ugc_recipe.ingredients_tags_placeholder')"
                    :max-tags="maxTags"
                    v-model="searchTagQuery"
                    :autocomplete-items="autocompleteIngredientsContent"
                    @tags-changed="handleTagsChanged"
                    :add-only-from-autocomplete="true"
                    ref="ingredients"
                    class="w-100"
                  ></vue-tags-input>
                </div>
              </div>
              <!-- Times -->
              <a href="#" id="times" class="hidden-anchor"></a>
              <div class="col-12">
                <!-- Preparation time -->
                <div class="d-inline-block pt-3 pr-3 pb-4 time">
                  <label for="preparation_time" class="text-xs text-uppercase" :class="[formErrors.time ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.preparation_time")}}*</label>
                  <input type="number" min="0" :placeholder="ft('globals.ugc_recipe.preparation_time_placeholder')" name="preparation_time" class="p-3 form-control w-100" v-model="recipeInfo.time.preparation_time">
                </div>
                <!-- Cooking time -->
                <div class="d-inline-block pt-3 pr-3 pb-4 time">
                  <label for="cooking_time" class="text-xs text-uppercase">{{ft("globals.ugc_recipe.cooking_time")}}</label>
                  <input type="number" min="0" :placeholder="ft('globals.ugc_recipe.cooking_time_placeholder')" name="cooking_time" class="p-3 form-control w-100" v-model="recipeInfo.time.cooking_time">
                </div>
                <!-- Resting time -->
                <div class="d-inline-block pt-3 pr-3 pb-4 time">
                  <label for="resting_time" class="text-xs text-uppercase">{{ft("globals.ugc_recipe.rest_time")}}</label>
                  <input type="number" min="0" :placeholder="ft('globals.ugc_recipe.cooking_time_placeholder')" name="resting_time" class="p-3 form-control w-100" v-model="recipeInfo.time.resting_time">
                </div>
              </div>
              <!-- Steps -->
              <a href="#" id="steps" class="hidden-anchor"></a>
              <div class="col-12 pt-3 pb-4">
                <label class="text-xs text-uppercase" :class="[formErrors.steps ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.step_title")}}*</label>
                <label class="text-xs">{{ft("globals.ugc_recipe.step_description")}}</label>
                <div class="step d-flex py-3" v-for="(step, index) in recipeInfo.steps" :key="`steps-${index}`">
                  <div class="step-index h6">{{index+1}}.</div>
                  <div class="step-description d-flex">
                    <textarea rows="3" :key="index" class="p-3 form-control w-100" v-model="recipeInfo.steps[index]" required></textarea>
                  </div>
                </div>
                <div class="step-actions d-flex">
                  <div class="d-flex add-step justify-content-center align-items-center mr-2">
                    <i class="fal fa-plus-circle" :class="{'disabled': !isAddableToRecipeInfo('steps', 2)}" @click="addStep"></i>
                  </div>
                  <div class="d-flex remove-step justify-content-center align-items-center mr-2">
                    <i class="fal fa-minus-circle" :class="{'disabled': recipeInfo.steps.length <= 2}" @click="removeStep"></i>
                  </div>
                </div>
              </div>
              <!-- Products -->
              <div class="col-12 pt-3 pb-4">
                <label class="text-xs text-uppercase" :class="[formErrors.products ? 'ugc-recipe-error' : '']" >{{ft("globals.ugc_recipe.products_title")}}*</label>
                <div v-for="(selectedProduct, index) in recipeInfo.products" :key="`product-select-${index}`" class="product-select-container pb-3 d-flex w-100">
                  <div class="product-select d-flex">
                    <select v-model="recipeInfo.products[index]" class="custom-select form-control w-100" required>
                      <option disabled selected value="">{{ft("globals.ugc_recipe.products_placeholder")}}</option>
                      <option v-for="product in products" :disabled="!isProductAddable(product.name)" :key="`product-select-${index}-${product.name}`" :value="product.name">{{product.title}}</option>
                    </select>
                  </div>
                  <div class="product-actions d-flex align-items-center pl-3">
                    <i v-if="recipeInfo.products.length == (index+1)" class="fal fa-plus-circle" @click="addProduct(index)" :class="{'disabled': !isAddableToRecipeInfo('products',1)}"></i>
                    <i v-if="recipeInfo.products[index] && recipeInfo.products.length != (index+1)" class="fal fa-minus-circle" @click="removeRecipeInfoElement('products', index)"></i>
                  </div>
                </div>
                <small class="d-block py-3">*{{ft("globals.ugc_recipe.products_help")}}</small>
              </div>
              <!-- Accessories -->
              <a href="#" id="accessories" class="hidden-anchor"></a>
              <div class="col-12 pt-3 pb-4">
                <label class="text-xs text-uppercase">{{ft("globals.ugc_recipe.accessories_title")}}</label>
                <div v-for="(selectedAccessory, index) in recipeInfo.accessories" :key="`accessory-select-${index}`" class="accessory-select-container pb-3 d-flex w-100">
                  <div class="accessory-select d-flex">
                    <select v-model="recipeInfo.accessories[index]" class="custom-select form-control w-100">
                      <option disabled selected value="">{{ft("globals.ugc_recipe.accessories_placeholder")}}</option>
                      <option v-for="accessory in accessories" :disabled="!isAccessoryAddable(accessory.name)" :key="`accessory-select-${index}-${accessory.name}`" :value="accessory.name">{{accessory.title}}</option>
                    </select>
                  </div>
                  <div class="accessory-actions d-flex align-items-center pl-3">
                    <i v-if="recipeInfo.accessories.length == (index+1)" class="fal fa-plus-circle" @click="addAccessory(index)" :class="{'disabled': !isAddableToRecipeInfo('accessories',1)}"></i>
                    <i v-if="recipeInfo.accessories[index] && recipeInfo.accessories.length != (index+1)" class="fal fa-minus-circle" @click="removeRecipeInfoElement('accessories', index)"></i>
                  </div>
                </div>
              </div>
              <!-- Upload img -->
              <a href="#" id="upload_img" class="hidden-anchor"></a>
              <div class="col-12 pt-3 pb-4">
                <label class="text-xs text-uppercase" :class="[formErrors.image ? 'ugc-recipe-error' : '']">{{ft("globals.ugc_recipe.upload_picture")}}*</label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="image" @change="onAttachmentChange($event, 'image')" required>
                  <label class="custom-file-label" for="image">{{uploadImagePlaceHolder}}</label>
                </div>
              </div>
              <!-- Errors -->
              <a href="#" id="errors" class="hidden-anchor"></a>
              <div v-if="Object.keys(formErrors).length > 0" class="col-12 pt-3 pb-4 ugc-recipe-error">
                <label class="text-xs text-uppercase">{{ft("globals.ugc_recipe.forgot")}}</label>
                <div class="errors">
                  <ul>
                    <li v-for="(errorText, index) in formErrors" :key="`error-${index}`" class="d-block">{{errorText}}</li>
                  </ul>
                </div>
              </div>
              <!-- Shots advices -->
              <div v-if="content.photo_tips" class="col-12 pt-3 pb-4">
                <div class="shots-advices-container w-100 d-flex flex-column">
                  <div class="shots-advices py-4" v-if="content.photo_tips.description" v-html="content.photo_tips.description"></div>
                  <div class="shots-pictures d-flex w-100">
                    <div v-for="index in 3" class="shots-picture" :key="`shots-${index}`">
                      <img 
                        v-if="content.photo_tips[`photo_${index}`]"
                        :src="content.photo_tips[`photo_${index}`].url"
                        class="img-fluid"
                        :alt="content.photo_tips[`photo_${index}`].alt"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex pt-4">
                <div class="row">
                  <div v-if="content.caption" class="col-12 col-md-6 d-flex flex-grow-1 text-xs">{{content.caption}}</div>
                  <div class="col-12 col-md-6 d-flex align-items-center justify-content-center pt-md-0 pt-4">
                    <input type="submit" class="btn btn-primary w-100 h-100 text-uppercase" :value="ft('globals.ugc_recipe.send')" :disabled="submitInProgress">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="py-5 w-100 text-center loading position-absolute-center" v-if="submitInProgress">
      <i class="fad fa-spinner fa-spin fa-3x text-primary fa-pulse"></i>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~bootstrap/scss/_forms";

[recipe-upload] {
  .custom-file-label::after {
    content: var(--custom-file-label);
  }

  .icon-placeholder {
    width: 32px;
    height: 32px;
  }

  .ugc-recipe-form {

    .ugc-recipe-error {
      color: #f00;
    }

    label {
      display: block;
      margin-bottom: 0.85rem;
    }

    textarea {
      resize: none;
    }

    .custom-select {
      padding-right: 2.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .vue-tags-input {
      max-width: none !important;

      ::v-deep .input {
        padding: .5rem !important;
        @extend .form-control;
        @include border-radius($input-border-radius, 0);
        @include box-shadow($input-box-shadow);
        @include transition($input-transition);

        .tag {
          background-color: $primary;
        }

        .new-tag-input-wrapper {
          min-height: 30px;

          input {
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.5;
          }
        }
      }
    }

    .ingredient-container {
      .ingredient-quantity {
        width: 6rem;
      }

      .ingredient-type  {
        flex-grow: 1;
      }
    }

    .shots-advices-container {
      .shots-picture {
        width: 32%;
        margin-right: 2%;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .product-select-container, .accessory-select-container {

      .product-select, .accessory-select {
        flex-grow: 1;
      }

      .product-actions, .accessory-actions {
        width: 40%;
      }

    }

    .step-actions, .ingredient-actions, .accessory-actions, .product-select-container {

      i {
        font-size: 2rem;
        color: $gray-900;
        cursor: pointer;

        &.disabled {
          cursor: default;
          opacity: 0.2;
        }
      }
    }

    .step {
      .step-description {
        flex-grow: 1;
      }
      .step-index {
        width: 2.5rem;
      }

    }

    .step-actions {
      padding-left: 3rem;

      .add-step, .remove-step {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .time {
      max-width: 30%;
    }

    .container-radio {
      width: 28%;
    }

    .course-container {
      width: 20%;
      transition: all 0.2s;

      &.active,
      &:active,
      &:focus,
      &:hover,
      &.selected {
        color: $primary;

        .filter-url {
          stroke: $primary;
          color: $primary;
        }
      }

      .filter-url {
        fill: none;
        cursor: pointer;
        stroke: $black;
        color: $black;
        transition: stroke 0.2s ease-in-out, color 0.2s ease-in-out;
        text-decoration: none;

      }

      .filter-icon {
        font-size: 3rem;
      }
    }
  }

  .ingredients-modal-content {
     min-height: 400px;
  }

  @include media-breakpoint-down(lg) {
    .ugc-recipe-form {
      .product-select-container, .accessory-select-container {
        .product-actions, .accessory-actions {
          width: 25%;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .ugc-recipe-form {
      .course-container {
        width: 33%;
      }

      .time {
        max-width: 48%;
      }

      .product-select-container, .accessory-select-container {
        .product-actions, .accessory-actions {
          width: auto;
        }
      }
    }
  }

  .v-autocomplete {
    .v-autocomplete-input-group {
      .v-autocomplete-input {
        width: 100%;
        border: none;
        background-color: $gray-200;
        padding: 0.85rem;
      }
    }

    .v-autocomplete-list {
      width: 100%;
      overflow-y: scroll;
      max-height: 200px;
    }
  }
}

</style>
