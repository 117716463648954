<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { map } from "lodash";
import Intersect from "vue-intersect";

export default {
  mixins: [fandomBasicMixin],
  components: { Intersect },
  data: function () {
    return {
      inherents: [],
      init: false
    };
  },
  mounted() {
  },
  methods: {
    headingContent(inherent) {
      return { 
        title: this.getContent(inherent).title, 
        width: 12,
        padding_bottom: 3 
      }
    },
    initInherent() {
      Fandom.post({
        data: { 
          main_content_name: this.containerContent.name, 
          exclude_content_ids: map(globalState.pageModel.name_to_content, c => c["id"]),
          main_content_record_id: this.content.$id
        },
        url: "/api/v5/get_user_inherents_contents",
        success: (data) => {
          Fandom.getContentsCallback(
            data.main_content_name, 
            data, 
            (data) => {
              this.init = true;
              this.inherents = Fandom.getContent(data.main_content_name).children;
            }
          )}
        });
    },
    appendInherent(content) {
      Fandom.post({
        data: { 
          main_content_name: this.containerContent.name, 
          exclude_content_ids: map(globalState.pageModel.name_to_content, c => c["id"]),
          main_content_record_id: this.content.$id,
          category_only: content.name
        },
        url: "/api/v5/append_user_inherents_contents",
        success: (data) => {
            Fandom.getContentsCallback(content.name, data, null, true);
          }
        });
    }
  }
};
</script>

<template>
  <intersect @enter.once="initInherent()">
    <div user-inherents-component>
      <template v-if="init" v-for="inherent in inherents">
        <stripe-text :content="headingContent(inherent)" :opt-container-content="containerContent"></stripe-text> 
        <component :is="getContent(inherent).layout" :content="getContent(inherent)" :key="getContent(inherent).name" :opt-container-content="containerContent" :opt-append-contents="appendInherent"></component>
      </template>
      <div class="py-5 w-100 text-center" v-if="!init">
        <i class="fad fa-spinner fa-spin text-primary fa-pulse fa-3x"></i>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss">
</style>