<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin]
}
</script>

<template>
  <grid-fixed :content="content" :opt-container-content="containerContent"></grid-fixed>
</template>