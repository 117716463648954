<script>
  export default {
    data() {
      return {
        content: {}
      };
    }
  }
  </script>
    
    <template>
      <div modal-gigya-component>

        <div class="modal fade" id="modal-user-session-expired" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modal-user-session-expired" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Sitzung abgelaufen!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.</p>
                <p class="small text-muted mb-0">* Stellen Sie für eine korrekte Navigation sicher, dass Sie sich nicht im Inkognito-Modus befinden.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
              </div>
            </div>
          </div>
        </div>
    
        <div class="modal fade" id="modal-user-changed" tabindex="-1" aria-labelledby="modal-user-changed" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Fertig!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Informationen erfolgreich aktualisiert.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
              </div>
            </div>
          </div>
        </div>
    
        <div class="modal fade" id="modal-user-marketing-information" tabindex="-1" aria-labelledby="modal-user-marketing-information" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Marketing-Information</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" id="modal-user-marketing-information-screenset">
              </div>
            </div>
          </div>
        </div>
    
        <div class="modal fade" id="modal-marketing-information" tabindex="-1" aria-labelledby="modal-marketing-information" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Marketing-Information</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="modal-login-error" tabindex="-1" aria-labelledby="modal-login-error" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Fehler bei der Dateneingabe</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
              </div>
            </div>
          </div>
        </div>
    
      </div>
    </template>
    
    <style lang="scss" scoped>
    </style>