<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
  },
  computed: { }
};
</script>

<template>
  <div
    option-small
    class="border text-uppercase d-flex align-items-center m-1 cursor-pointer bg-white"
    :class="active ? 'border-primary' : 'border-gray-300'"
    @click="$emit('toggle')"
  >
    <div class="side-square-checked h-100 position-relative bg-contain-center" :style="{'background-image': getContentResponsiveImageURL('thumbnail', content)}" v-if="content.thumbnail || active">
      <div class="position-absolute-center bg-pink d-flex justify-content-center align-items-center" :class="{'opacity-80': content.thumbnail}" v-if="active">
        <i class="fal text-white fa-lg fa-check"></i>
      </div>
    </div>
    <div class="px-3 title" :class="{'text-pink': active}">{{content.title}}</div>
  </div>
</template>

<style lang="scss">
[option-small] {
  $square-dim: 3rem;
  height: $square-dim;
  max-width: 32%;

  @include media-breakpoint-down(lg) {
    max-width: 48%;
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }

  .side-square-checked {
    width: $square-dim;
    min-width: $square-dim;
  }

  .opacity-80 {
    opacity: 0.8;
  }

  .title {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 270px;
    width: 100%;
    white-space: nowrap;
  }
}
</style>