<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    user() {
      return get(this.globalState.pageModel, ["user"], {});
    },
    componentStyle() {
      return {
        "background-image": this.user.background_image ? Fandom.getBackgroundUrl(this.user.background_image.url) : null
      };
    },
    userInitials() {
      return `${this.user.username?.[0] ?? ""}`.toUpperCase();
    }
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getUserAvatar() {
      const anonymousAvatar = get(this.globalState.pageModel, ["layout", "content", "anonymous_avatar", "url"], false);
      return get(this.user, ["avatar", "url"], anonymousAvatar);
    },
    openModal() {
      $(this.$refs["edit-pictures"]).modal("show");
    },
    updateUserImage(image) {
      const file = this.$refs[image].files[0];
      const filesData = new FormData();
      filesData.append("file", file);
      this.loading = true;
      Fandom.ajax({
        method: "POST",
        data: filesData,
        processData: false,
        contentType: false,
        url: `/api/v5/update_user_picture/${image}`,
        success: (data) => {
          if(Fandom.exists(data.new_image)) {
            this.user[image] = data.new_image;
          }
          if(this.errors) {
            // Errors
          }
          this.loading = false;
        }
      });
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="border-bottom position-relative bg-cover-center" :style="componentStyle" user-dashboard-component>
    <label for="user-avatar" class="position-absolute-center d-flex align-items-center user-avatar justify-content-center cursor-pointer" :class="{'has-avatar': getUserAvatar()}"></label>
    <label for="user-background-image" class="position-absolute edit-background cursor-pointer p-3 text-primary">
    </label>
    <div class="container">
      <div class="row flex-column user-dashboard-content align-items-center justify-content-center">
        <div class="col-auto mt-auto mb-4">
          <div
            class="bg-cover-center user-dashboard-avatar position-relative rounded-circle cursor-pointer"
            :style="{'background-image': getUserAvatar() ? getBackgroundUrl(getUserAvatar()) : null }"
            @click="openModal"
            >
            <div v-if="!getUserAvatar()" class="position-absolute-center d-flex align-items-center justify-content-center user-icon">
              {{userInitials}}
            </div>
          </div>
        </div>
        <div class="col-auto mb-auto mt-0">
          <p class="m-0 p-0 text-uppercase dashboard-title">{{user.username}}</p>
        </div>
      </div>
    </div>
    <div class="d-none">
      <input type="file" ref="avatar" id="user-avatar" @change="updateUserImage('avatar')">
      <input type="file" ref="background_image" id="user-background-image" @change="updateUserImage('background_image')">
    </div>
  </div>
</template>

<style lang="scss" scoped>
[user-dashboard-component] {
  .edit-background {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    color: $white;
    opacity: 0;
    transition: opacity .25s linear;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  .dashboard-title { 
    font-size: 1.25rem;
  }

  .user-dashboard-content {
    height: 18rem;
  }

  .user-dashboard-avatar {
    width: 7.5rem;
    height: 7.5rem;
    overflow: hidden;
    color: $primary;
    background-color: $light;

    .gradient {
      transition: opacity .25s linear;
      background-color: $light;
    }

  }

  .user-icon {
    pointer-events: none;
    font-size: 2.5rem !important;
  }

  .user-avatar{
    width: 9rem;
    height: 8rem;
    top: 18%;
    left:46%;
    right: 0;
    bottom: 0;
    z-index: 1
  }
}
</style>
