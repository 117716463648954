<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { generateToken } from 'src/modules/recaptcha_v3_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  data() {
    return {
      form: {
        data: {},
        errors: [],
        loading: false,
        success: false,
      },
      user: {},
      formUrl: '/upload_simple_form'
    }
  },
  computed: {
    formFields() {
      return JSON.parse(this.content.form_fields).fields || [];
    }
  },
  methods: {
    getContentStyle () {
      var styleObject = {};
      if (Fandom.exists(this.content.background_color)) {
        styleObject["background-color"] = this.content.background_color;
      }
      if (Fandom.exists(this.content.background_image)) {
        styleObject["background-image"] = this.getContentResponsiveImageURL("background_image");
      }
      if (Fandom.exists(this.content.text_color)) {
        styleObject["color"] = this.content.text_color;
      }
      return styleObject;
    },
    getFieldInputName(fieldName) {
      return `user_upload_interaction[${fieldName}]`;
    },
    getFormData() {
      const formData = new FormData();
      this.formFields.forEach(field => {
        const formDataValue = get(this.user, field.name, '');
        if (!!formDataValue) {
          formData.append(this.getFieldInputName(field.name), formDataValue);
        }
      });

      formData.append("interaction_id", this.content.interaction.id);
      formData.append("content_id", this.containerContent.id);

      return formData;
    },
    submitSimpleForm(event) {
      if (!this.form.loading) {
        this.form.success = false;
        this.form.loading = true;
        this.form.errors = null;
        let submitForm = (token) => {
          const data = this.getFormData();
          if (token)
            data.append("recaptcha_enterprise_token", token);

          Fandom.ajax(
            {
              async: true,
              processData: false,
              contentType: false,
              method: "POST",
              url: this.formUrl,
              data: data,
              success: (data) => {
                this.form.success = data.success;
                if (this.form.success) {
                  if (Fandom.exists(this.content.thank_you_page)) {
                    const thankYouUrl = this.content.thank_you_page.hasOwnProperty("url") ? this.content.thank_you_page.url : this.content.thank_you_page;
                    window.location.href = Fandom.applyContextToUrl(thankYouUrl);
                  }
                } else {
                  this.form.errors = data.errors;
                }
              }
            },
            () => {
              this.progress = null;
              this.form.loading = false;
            }
          );
        }

        gigya.accounts.getAccountInfo({ callback: (response) => {
          if (response.errorCode === 0) {
            this.user = { first_name: response.profile.firstName, last_name: response.profile.lastName, email: response.profile.email };
            if (globalState.pageModel.aux.recaptcha_enterprise) {
              generateToken(submitForm, "submit");
            } else {
              submitForm();
            }
          } else {
            console.log("%cScreen set error: getAccountInfo", "color: #dc3545", response.errorCode, response.errorDetails);
          }
        }});
        
      }
    },
  }
};
</script>

<template>
  <div :id="content.name" v-easyadmin="easyadminId" :class="contentType" class="bg-cover-center themed-item" v-if="isContentVisibleToUser()" :style="[theme, getContentStyle()]" simple-form-component>
    <div class="container">
      <div class="row justify-content-center align-items-center py-5">
        <div class="col-sm-12 col-md-8 col-sm-6">
          <form-feedback :errors="form.errors" :success="form.success"></form-feedback>
          <text-section class="text-center" :content="content" :opt-container-content="optContainerContent"/>
          <div class="w-100 d-flex justify-content-center pt-4">
            <button class="btn btn-primary" @click="submitSimpleForm" :disabled="form.loading">{{content.button_text}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>