<script>
import { fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin } from "src/modules/fandom_mixins_module.js";
import tileMixin from '../../modules/kenwood_austria_tile_mixin.js';
import { get } from 'lodash';

export default {
	mixins: [fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin, tileMixin],

	computed: {
		url() {
			return this.isAccessible ? get(this.content, ['url', 'url'], Fandom.getContentURL(this.content)) : 'javascript:void(0)';
		},
	}
}
</script>

<template>
	<div v-easyadmin="easyadminId" :class="contentType">
		<a class="tile deco-none cursor-pointer" :target="getTarget(content.url)" :href="url" :style="theme" @click="lockedContentClickHandler($event)" tile-cookbook-component>
			<div class="col-12">
				<div class="row">
					<div class="col-12 d-flex flex-column p-0 overflow-hidden">
						<div class="ar-1by1 h-100 bg-cover-center w-100 animate-zoom" :style="{ 'background-image': getContentResponsiveImageURL('thumbnail') }"></div>
						<div class="tile__lock-icon position-absolute d-flex align-items-center justify-content-start" v-if="!isAccessible">
							<i class="far fa-lock-alt fa-lg p-4 text-white"></i>
						</div>
					</div>
					<div class="text-section">
						<div class="col-12 d-flex flex-column">
							<div class="tile__content text-left w-100 justify-content-between d-flex flex-column">
								<component v-if="owner" class="owner d-flex align-items-center pt-3">
									<div class="owner-avatar bg-cover-center d-flex justify-content-center align-items-center" :style="avatarStyle">
										<span v-if="!ownerAvatarUrl" class="text-white">{{ ownerInitials }}</span>
									</div>
									<div class="owner-title d-flex flex-column align-items-end pl-2">
										<div class="owner-name">{{ owner.first_name }} {{ owner.last_name }}</div>
									</div>
								</component>
							</div>
						</div>
						<div class="col-12 d-flex flex-column pt-3">
							<h3 v-if="content.title">
								{{ content.title }}
							</h3>
							<div v-if="content.description" v-html="content.description"></div>
						</div>
					</div>
				</div>
			</div>
		</a>
	</div>
</template>

<style lang="scss" scoped>
[tile-cookbook-component] {

	.text-section {
		min-height: 250px;
	}

	.ar-1by1 {
		border-bottom: 0.5px solid #dee2e6;
		max-height: 50%;
	}

	.row {
		min-height: 560px;
		max-height: 560px;
		border: 0.5px solid #dee2e6;
		margin-bottom: 20px;
	}

	.tile__content {
		.owner {
			&-name {
				font-weight: 600;
				font-size: .75rem;
				max-height: 36px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&-avatar {
				border-radius: 50%;
				border: 2px solid $white;
				width: 2.5rem;
				height: 2.5rem;
				background-color: $primary;
			}
		}
	}

	h3 {
		font-size: 1.5rem;
	}

	.tile__lock-icon {
		right: 0;
		bottom: 0;
		width: 7rem;
		background-color: $primary;
		border-top-left-radius: 3rem;
		border-bottom-left-radius: 3rem;
	}
}
</style>