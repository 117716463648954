<template>
  <div class="equipment col-12 py-md-4 px-0 px-md-3 pb-4">
    <div class="bg-gray-light p-4">
      <h5 class="pb-1 equipment__title">{{ft("globals.recipe.used_product")}}</h5>
      <div class="accessory-carousel px-4" ref="accessory-carousel">
        <div class="accessory-carousel-item px-2" v-for="(accessory, index) in accessories" :key="`carousel-slide-${index}`">
          <div class="ar-1by1 position-relative bg-white">
            <a :href="accessory.url ? accessory.url.url : 'javascript:void(0)'" :target="getTarget(accessory.url)">
              <div v-if="accessory.thumbnail" class="w-100 h-100 position-absolute bg-cover-center" :style="{'background-image': getBackgroundUrl(accessory.thumbnail.url)}">
                <div class="accessory-shopping-icon bg-gray-primary" v-if="accessory.url">
                  <i class="far fa-shopping-cart"></i>
                </div>
              </div>
            </a>
          </div>
          <div class="mt-2 pt-1 small">{{accessory.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "flickity";
import { fandomTranslate } from "src/modules/common_module";

export default {
  props: {
    accessories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      carousel: null
    }
  },
  mounted() {
    if (Fandom.exists(this.accessories)) {
      this.initCarousel();
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    ft: fandomTranslate,
    getTarget: Fandom.getTarget,
    initCarousel() {
      this.carousel = new Flickity(this.$refs["accessory-carousel"], {
        draggable: this.isMobile ? '>2' : '>3',
        prevNextButtons: true,
        arrowShape: { 
          x0: 10,
          x1: 60, y1: 50,
          x2: 60, y2: 40,
          x3: 20
        },
        pageDots: false,
        cellAlign: "left",
        groupCells: this.isMobile ? 2 : 3,
        wrapAround: false,
        contain: true,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.equipment {
  &__title {
    font-family: $gotham-font;
    font-style: normal;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}

::v-deep .flickity-prev-next-button {
  background: transparent;

  &.next {
    right: -10px;
  }

  &.previous {
    left: -10px
  }
}

.accessory-carousel-item {
  width: 33.33%;

  @include media-breakpoint-down(sm) {
    width: 50%;
  }

  .accessory-shopping-icon {
    width: 36px;
    height: 36px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
  }
}
</style>